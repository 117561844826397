import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActivityService } from '../../services/activityService'


export const getOneActivityThunk = createAsyncThunk(
  'activity/getOneThunk',
  async (data, { rejectWithValue }) => {
    try {
      const req = await getActivityService(data);
      if (req.success === false) {
        return rejectWithValue(req)
      }
      return req;
    } catch (e) {
      return rejectWithValue(e)
    }
  }
);


