import React, { useState, useEffect } from 'react';
import "./Certificates.scss"
//import ManyActivitiesSection from '../../components/myActivities/ManyActivitiesSection'
import arrowTitle from '../../assets/arrow_yellow.svg'
import activityImage from '../../assets/bg_actividades.png'
import lineBlue from '../../assets/bg_line-100.jpg'
import CertificateCard from "../../components/certificateCard/CertificateCard"
import Backdrop from "../../components/backdrop/Backdrop"
import { useDispatch, useSelector } from 'react-redux';
import { getAllCertificatesThunk } from '../../redux/certificates/thunks';
import { getAccountThunk } from '../../redux/user/thunks';


export default function Certificates() {
    const dispatch = useDispatch()
    let certificatesAll = useSelector(state => state.certificates.certificatesAll);
    let loading = useSelector(state => state.certificates.loading);
    let message = useSelector(state => state.certificates.message);

    useEffect(() => {
        dispatch(getAllCertificatesThunk())
        dispatch(getAccountThunk())
    }, [])

    if (loading) {
        return <Backdrop show={loading} />
    }
    
    return (
        <main id='activities'>
           { /*<div >
                <div></div>
                <div>
                    <div>
                    <p id='titleMyActivities' >
                        <span className='title-border-white'>MIS</span> <br />
                        <span className='title-bold-white'><span className='textLineBlue'>CERTIFICADOS</span> <br /> </span>
                    </p>
                    <img  src={arrowTitle} alt="" />
                    </div>
                </div>
    </div>*/}
            <section
                id='sectionSecondary'
                className={"section "}>
                {
                    certificatesAll && certificatesAll.length
                        ?
                        <React.Fragment>
                            <div className="titleProp">
                                <p className={'title-border-blue'}>  TODOS
                                    <img src={lineBlue} alt="" />
                                    <p  style={{color: "#0157a0"}}> MIS  <span className={'textLineBlue3'}>CERTIFICADOS</span> </p>
                                </p>
                            </div>
                            <div className="columns is-multiline is-centered">
                                {
                                   certificatesAll.map((certificate, index) => {
                                    if(certificatesAll.revokedCertificate !== true){
                                        return (
                                            <CertificateCard certificate={certificate} key={index} />
                                        )
                                    }
                                       
                                    })
                                    
                                }
                            </div>
                        </React.Fragment>
                        :
                        <div className="titleProp" style={{fontSize:'3.4rem', lineHeight:'3.4rem', paddingTop: '10rem'}}>
                        <div className={'title-bold-blue text-center'}> 
                             <p>NO SE ENCUENTRAN</p>
                            <p>CERTIFICADOS DISPONIBLES</p>
                        </div>
                    </div>    
                }
            </section>
        </main>
    )
}