import React, { useState, useEffect } from 'react';
import "./Activities.scss"
import arrowTitle from '../../assets/arrow_yellow.svg'
import lineBlue from '../../assets/bg_line-100.jpg'
import ActivityCard from "../../components/activityCard/ActivityCard"
import Backdrop from "../../components/backdrop/Backdrop"
import { useDispatch, useSelector } from 'react-redux';
import { allActivitiesThunk, activitySubscribeThunk } from '../../redux/activities/thunks';
import { hideToast } from '../../redux/activities/activitiesSlice';
import Toast from '../../components/toast/Toast';
import { getAccountThunk } from '../../redux/user/thunks';

export default function MyActivities() {
    const [viewMoreCount, setViewMoreCount] = useState(6)
    const [shownActivities, setShownActivities] = useState(6)
    const [activitySelected, setActivitySelected] = useState()

    const dispatch = useDispatch()
    let allActivities = useSelector(state => state.activities.allActivities);
    let redirect = useSelector(state => state.activities.redirect);
    let loading = useSelector(state => state.activities.loading);
    let success = useSelector(state => state.activities.subscribeActivitySuccess);
    let message = useSelector(state => state.activities.message);
    let userData = useSelector(state => state.user.data);
    let subscribeActivityLink = useSelector(state => state.activities.subscribeActivityLink);
    let refresh = useSelector(state => state.activities.subscribeActivityRefresh);
    let visibleToast = useSelector(state => state.activities.showToast);
    let error = useSelector(state => state.activities.error);

    const handleToast = () => {
        dispatch(hideToast())
    }

    useEffect(() => {
      if(redirect) {
        window.location.replace('/myActivities')
      }
        dispatch(allActivitiesThunk())
        dispatch(getAccountThunk())
    }, [])

    useEffect(() => {
      if(redirect) {
        window.location.replace('/myActivities')
      }
        if (success) {
            dispatch(allActivitiesThunk())
        }
    }, [refresh])

    useEffect(() => {
        if (activitySelected) {
            dispatch(activitySubscribeThunk(activitySelected))
        }
    }, [activitySelected])

    useEffect(() => {
        if (subscribeActivityLink) {
            window.location.replace(subscribeActivityLink)
        }
    }, [subscribeActivityLink])

    const viewMore = () => {
        setViewMoreCount(viewMoreCount + 6)
        setShownActivities(shownActivities + 6)
    }

    if (loading) {
        return <Backdrop show={loading} />
    }

    return (
      <main id="activities">
        <section
          style={{ paddingTop: 25 }}
          id="sectionSecondary"
          className={"section bg-skyBlue"}
        >
          {allActivities && allActivities.length ? (
            <React.Fragment>
              <div className="titleProp">
                <p className={"title-border-white"}>{!userData?._id ? 'TODAS LAS ACTIVIDADES' : 'ACTIVIDADES'}</p>
                {userData?._id && <>
                <p className={"title-bold-white"}>
                  {" "}
                  EN LAS QUE
                  <img src={lineBlue} alt="" />
                  <br />
                  <span className={"textLineBlue2"}> NO ESTOY </span> SUSCRIPTO
                </p>
                </>}
              </div>
              <div className="columns is-multiline is-centered">
                {allActivities.map((activity, index) => {
                  return (
                    <ActivityCard
                      myActivities={false}
                      paid={false}
                      setActivitySelected={setActivitySelected}
                      show={index < viewMoreCount}
                      userData={userData}
                      key={index}
                      isMembership={true}
                      activity={activity}
                      isMember={userData?.membershipStatus === 'authorized' ? true : false}
                    />
                  );
                })}
              </div>
              {
              (allActivities.length > 6 &&
                shownActivities <= allActivities.length) && (
                  
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="button blue" onClick={viewMore}>
                      Ver mas
                    </button>
                  </div>
                )}
            </React.Fragment>
          ) : (
            <div
              className="titleProp"
              style={{
                fontSize: "3.4rem",
                lineHeight: "3.4rem",
                paddingTop: "10rem",
              }}
            >
              <div className={"title-bold-white text-center"}>
                <p>NO SE ENCONTRARON</p>
                <p>ACTIVIDADES DISPONIBLES</p>
              </div>
            </div>
          )}
        </section>
        <Toast
          visible={activitySelected?.paid ? false : visibleToast}
          handleToast={handleToast}
          message={message}
          title={"Subscripcion"}
        />
        <Backdrop show={loading} />
      </main>
    );
}