import React from 'react';

function NotFound() {
  return (
    <div className="titleProp" style={{fontSize:'3.4rem', lineHeight:'3.4rem', paddingTop: '10rem'}}>
    <div className={'title-bold-blue text-center'}> 
         <p>PÁGINA NO ENCONTRADA</p>
    </div>
</div>  
  );
}

export default NotFound;
