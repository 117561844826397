import React, { useState, useEffect } from 'react';
import "./MyActivities.scss"
//import ManyActivitiesSection from '../../components/myActivities/ManyActivitiesSection'
import arrowTitle from '../../assets/arrow_yellow.svg'
import activityImage from '../../assets/bg_actividades.png'
import lineBlue from '../../assets/bg_line-100.jpg'
import ActivityCard from "../../components/activityCard/ActivityCard"
import { useDispatch, useSelector } from 'react-redux';
import { myActivitiesThunk, activitySubscribeThunk } from '../../redux/activities/thunks';
import Backdrop from "../../components/backdrop/Backdrop"
import { getAccountThunk } from '../../redux/user/thunks';

export default function MyActivities() {
    const [viewMoreCount, setViewMoreCount] = useState(6)
    const [shownActivities, setShownActivities] = useState(6)
    const dispatch = useDispatch()
    let myActivities = useSelector(state => state.activities.myActivities);
    let userData = useSelector(state => state.user.data);
    let loading = useSelector(state => state.activities.loading);
    const [activitySelected, setActivitySelected] = useState()
    let subscribeActivityLink = useSelector(state => state.activities.subscribeActivityLink);


    useEffect(() => {
        dispatch(myActivitiesThunk())
        dispatch(getAccountThunk())
    }, [])

    const viewMore = () => {
        setViewMoreCount(viewMoreCount + 6)
        setShownActivities(shownActivities + 6)
    }

    
    useEffect(() => {
        if (activitySelected) {
       
            dispatch(activitySubscribeThunk(activitySelected))
        }
    }, [activitySelected])

    useEffect(() => {
        if (subscribeActivityLink) {
            window.location.replace(subscribeActivityLink)
        }
    }, [subscribeActivityLink])


    if (loading) {
        return <Backdrop show={loading} />
    }

    return (
      <main id="activities">
        {/*<div >
                <div></div>
                <div>
                    <div>
                    <p id='titleMyActivities' >
                        <span className='title-border-white'>MIS</span> <br />
                        <span className='title-bold-white'><span className='textLineBlue'>ACTIVIDADES</span> <br /> </span>
                    </p>
                    <img  src={arrowTitle} alt="" />
                    </div>
                </div>
            </div>*/}
        <section id="sectionSecondary" className={"section"}>
          {myActivities && myActivities.length ? (
            <React.Fragment>
              <div className="titleProp">
                <p className={"title-border-blue"}>ACTIVIDADES</p>
                <p className={"title-bold-blue"}>
                  {" "}
                  EN LAS QUE
                  <img src={lineBlue} alt="" />
                  <p>
                    {" "}
                    <span className={"textLineYelow"}> ESTOY </span> INSCRIPTO
                  </p>
                </p>
              </div>
              <div className="columns is-multiline is-centered">
                {myActivities.map((activity, index) => {
                  if (activity) {
                    return (
                      <ActivityCard
                        setActivitySelected={setActivitySelected}
                        loading={loading}
                        paid={true}
                        myActivities={true}
                        show={index < viewMoreCount}
                        userData={userData}
                        key={index}
                        isMembership={true}
                        activity={activity}
                        isMember={userData?.membershipStatus === 'authorized' ? true : false
                        }
                      />
                    );
                  }
                })}
              </div>
              {(myActivities.length > 6 &&
                shownActivities <= myActivities.length) && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="button blue" onClick={viewMore}>
                      Ver mas
                    </button>
                  </div>
                )}
            </React.Fragment>
          ) : (
            <div
              className="titleProp"
              style={{
                fontSize: "3.4rem",
                lineHeight: "3.4rem",
                paddingTop: "10rem",
              }}
            >
              <div className={"title-bold-blue text-center"}>
                <p>NO SE ENCUENTRA</p>
                <p> INSCRIPTO EN NINGUNA ACTIVIDAD VIGENTE</p>
              </div>
            </div>
          )}
        </section>
      </main>
    );
}