import React, { useEffect, useState } from 'react';
import arrowTitle from '../../assets/arrow_rigth.svg'
import arrowBtn from '../../assets/arrow_bottom.svg'
import lineBlue from '../../assets/bg_line-100.jpg'
import Backdrop from "../../components/backdrop/Backdrop"
import Toast from "../../components/toast/Toast"
import FeaturedActivity from '../../components/featuredActivity/FeaturedActivity'
import NextActivity from '../../components/nextActivity/NextActivity'
import "./Home.scss"
import { useDispatch, useSelector } from 'react-redux';
import { siteDataThunk } from '../../redux/siteData/thunks';
import { activitySubscribeThunk } from '../../redux/activities/thunks';
import { hideToast } from '../../redux/activities/activitiesSlice';
import { Link } from 'react-router-dom';
import { PUBLIC_IMG_URL } from '../../config/index';
import { getAccountThunk } from '../../redux/user/thunks';
import { getPopupThunk } from '../../redux/pop_ups/thunks';
import { AAOC_TV_URL } from '../../config';


export default function Home(props) {
    let aaoc_tv_redirect = useSelector(state => state.auth.aaoc_tv_redirect)
    let homeData = useSelector(state => state.siteData.homeData);
    let featuredActivity = useSelector(state => state.siteData.featuredActivity);
    let nextActivities = useSelector(state => state.siteData.nextActivities);
    let userActivityFeatured = useSelector(state => state.siteData.userActivityFeatured);
    let userSubscribeNextActivities = useSelector(state => state.siteData.userSubscribeNextActivities);
    let refresh = useSelector(state => state.activities.subscribeActivityRefresh);
    let loading = useSelector(state => state.activities.loading);
    let message = useSelector(state => state.activities.message);
    let loadingSiteData = useSelector(state => state.siteData.loading);
    let subscribeActivityLink = useSelector(state => state.activities.subscribeActivityLink);
    const [activitySelected, setActivitySelected] = useState()
    const dispatch = useDispatch()
    let visibleToast = useSelector(state => state.activities.showToast);
    let error = useSelector(state => state.activities.error);
    let pop_up = useSelector(state => state.pop_up)
    const stored_pop_up = localStorage.getItem('pop_up') !== pop_up.imgPath ? false : true
    const [show_pop_up, set_show_pop_up] = useState(stored_pop_up)
    const [user_token] = useState(localStorage.getItem('token'))
    const [aaoc_tv_url] = useState(AAOC_TV_URL + user_token)



    useEffect(() => {
        if (aaoc_tv_redirect) {
            console.log(aaoc_tv_redirect)
            window.location.replace(aaoc_tv_url)
        }
        dispatch(siteDataThunk())
        dispatch(getAccountThunk())
        dispatch(getPopupThunk())
    }, [refresh])

    useEffect(() => {
        if (subscribeActivityLink) {
            window.location.replace(subscribeActivityLink)
        }
    }, [subscribeActivityLink])

    const handleToast = () => {
        dispatch(hideToast())
    }

    useEffect(() => {
        if (activitySelected) {
            dispatch(activitySubscribeThunk({id: activitySelected._id, type: activitySelected.type}))
        }
    }, [activitySelected])

    const subscribeActivityFeatured = () => {
        dispatch(activitySubscribeThunk({id: featuredActivity._id, type: featuredActivity.type}))
    }

    const hide_pop_up = () => {
        localStorage.setItem('pop_up', pop_up.imgPath)
        set_show_pop_up(false)
    }


    if (loadingSiteData || aaoc_tv_redirect || loading) {
        return <Backdrop show={true} />
    }

    return (
    
        <React.Fragment>
            {pop_up?.visible &&
              <div className="pop-up" onClick={hide_pop_up} style={{display: show_pop_up ? 'fixed' : 'none'}}>
                    <div className="pop-up-content">
                        <img alt="" className = "pop_up_desktop" src={`${PUBLIC_IMG_URL}/${pop_up.imgPath}`}/>
                        <img alt="" className = "pop_up_mobile" src={`${PUBLIC_IMG_URL}/${pop_up.imgPathMobile}`}/>
                    </div>
                    
                    <div className="modal-close">                  
                    </div>
                </div>
            }

            <main id='home'>
                {
                    homeData ?
                        <section
                            style={{
                                backgroundImage: `url(${PUBLIC_IMG_URL}${homeData.banner.imgPath})`
                            }}
                            className='section sectionPrimary'>
                            <div className="columns is-gapless mt-6">
                                <div className="column is-three-fifths">
                                    <h1 className="titleProp">
                                        <span className='title-border-white'>{homeData.banner?.title1}</span> <br />
                                        <span className='title-bold-white'>{homeData.banner?.title2?.split(' ')[0]} <span className='textLineBlue'>{homeData.banner?.title2?.split(' ')[1]}</span> <br /> {homeData.banner?.title3} <img src={arrowTitle} alt="" width="80px" /> </span>
                                    </h1>
                                    <p className='white pt-4'>
                                        {homeData.banner?.description}
                                    </p>
                                    <div id='btn-more' className="field">
                                        <a href={homeData.banner?.url} className="control">
                                            <span className="button is-normal bg-white">
                                                <span className='blue'>Saber más</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            { (featuredActivity || nextActivities?.length) &&
                            <div className="columns is-centered is-gaples py-0 my-0">
                                <div id='float' className="column is-8">
                                    <h1 className="titleProp">
                                        <div className='text'>
                                            <span className='title-border-blue'>CONOZCA </span> <img src={lineBlue} alt="" />
                                            <br />
                                            <span className='title-bold-blue'><span className='textLineYelow' >LAS</span> ACTIVIDADES </span>
                                        </div>
                                        <div className='btn-title'>
                                            <img src={arrowBtn} alt="" />
                                        </div>
                                    </h1>
                                </div>
                            </div>
                            }
                        </section>
                        :
                        null
                }
                { (featuredActivity || nextActivities?.length) ?
                <section className='section sectionSecondary' style={{marginTop: '0px', paddingBottom: '40px', paddingTop: '10px'}}>
                    <div id='containerPosts'  className="columns">
                        {
                            featuredActivity
                                &&
                                <FeaturedActivity featuredActivity={featuredActivity} userActivityFeatured={userActivityFeatured} setActivitySelected={setActivitySelected} />
                                
                        }
                        {
                            nextActivities
                                &&
                                <div id='listPost' className={featuredActivity ? 'column is-6' : 'column is-12'}>
                                    <p className='blue subtitle is-4'>Próximas actividades</p>
                                    <div className="columns is-multiline is-gapless containerCardNext" >
                                        {
                                            nextActivities.map((activity, index) => <NextActivity key={index} index={index} userSubscribeNextActivities={userSubscribeNextActivities} activity={activity} setActivitySelected={setActivitySelected} nextActivities={nextActivities} />
                                            )
                                        }
                                        <div><Link to="/activities">Ver Todas las actividades</Link></div>
                                    </div>
                                </div>
                        }
                    </div>
                </section>
                :
                null
                }
            
            </main>
            <Toast visible={activitySelected?.paid ? false : visibleToast} handleToast={handleToast} message={message} title={error ? "Ha ocurrido un error, por favor intenta de nuevo" : "Felicidades!"} />
            <Backdrop show={loading} />
            
           
        </React.Fragment>
    
    )
}