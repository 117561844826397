import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBillingByUserService } from '../../services/billingServices'

export const getAllBillingsThunk = createAsyncThunk(
  'billings/allBillings',
  async (data, { rejectWithValue }) => {
    try {
      const req = await getBillingByUserService();
      if(req.status !== 200){
        return rejectWithValue(req.data)
      }
      if (!req.data.success) { 
         return rejectWithValue(req.data)
      }
      return req;
    } catch (e) {
      return rejectWithValue(e)
    }
  }
);
