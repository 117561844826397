import { createAsyncThunk} from '@reduxjs/toolkit';
import { getPopup } from '../../services/pop_upServices'

export const getPopupThunk = createAsyncThunk(
    'pop_ups/getPopUp',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getPopup();
        if (req.success !== true) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );