import React from "react"

export default function Input(props) {
    return (
        <>
            <label style={{
                color: props.isInvalid ? '#ff7300' : (props.section === 'section1' ? '#0157a0' : 'white'),
                fontSize: '20px'
            }}>
                {props.placeholder}
            </label>
            <input
                style={{
                    width: '100%',
                    minHeight: props.minHeight ? props.minHeight : '50px',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    border: props.isInvalid ? '1px solid red' : (props.section === 'section1' ? '1px solid #0157a0' : 'none'),
                    fontSize: '1.3rem',
                    outline: 'none',
                    color: props.section === 'section1' ? '#0157a0' : '#5d677f',
                    paddingLeft: props.paddingLeft ? props.paddingLeft : '10px',
                    ...props.styles
                }}
                type={props.type}
                value={props.value}
                onChange={props.handleChange}
                spellCheck={props.spellcheck ? props.spellcheck : false}
                disabled={props.disabled ? props.disabled : false}
                name={props.name}
            />
        </>
    );
}
