import React from "react";
import "./ArticleModal.scss";


export default function DietaryRequirements({handleChange, sub_type_state, show_other}) {
  
  return (
    sub_type_state === 'presencial' &&
    <React.Fragment>  
    <div className="field column is-6">
      <div className="control">
        <label style={{ color: "#0157a0", fontSize: 18 }}>Requerimientos dieta</label>
        <select
          name={"dietary_restrictions"}
          onChange={handleChange}
        >
          <option hidden></option>
          <option value="">No tengo ninguna restricción dietaria</option>
          <option value="Celiaco">Celiaco</option>
          <option value="Vegano">Vegano</option>
          <option value="Vegetariano">Vegetariano</option>
          <option value="Anti tolerante a la lactosa">Anti tolerante a la lactosa</option>
          <option value="Alérgico">Alérgico</option>
          <option value="Otro">Otro</option>
        </select>
      </div>
    </div>
    <div className="field column is-12" style={{display: show_other ? 'block' : 'none'}}>
      <div className="control">
      <label style={{ color: "#0157a0", fontSize: 18 }}>Detalle su requerimiento dietario</label>
        <input
          type="text"
          name={"dietary_comments"}
          onChange={handleChange}
        />
      </div>
    </div>

    <div className="field column is-12">
      <div className="control">
      <label style={{ color: "#0157a0", fontSize: 18 }}>Pedido especial</label>
        <input
          type="text"
          name={"special_requests"}
          onChange={handleChange}
        />
      </div>
    </div>
    </React.Fragment>
  );
}
