import React, { useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import "./login.scss"
import arrowTitle from '../../assets/arrow_yelow_1.svg'
import Input from '../../components/input/Input'
import { useDispatch, useSelector } from 'react-redux';
import { loginThunk } from '../../redux/auth/thunks';
import Backdrop from "../../components/backdrop/Backdrop"
import Toast from "../../components/toast/Toast"
import { hideToast } from '../../redux/auth/authSlice';

export default function Login() {
    const [data, setData] = useState();
    const [code, setCode] = useState('');
    const [searchParams] = useSearchParams();
    const [aaoc_tv_redirect] = useState(searchParams.get('aaoc_tv_redirect'))
    const [newPassword, setNewPassword] = useState('');
    const dispatch = useDispatch()
    const [alert_used, setAlert_used] = useState(false);
    let loading = useSelector(state => state.auth.loading);
    let error = useSelector(state => state.auth.error);
    let toastHidden = useSelector(state => state.auth.toastHidden);
    let message = useSelector(state => state.auth.message);
    let transferred = useSelector(state => state.auth.transferred);

 
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleChangeCode = (e) => {
        setCode(e.target.value)
    };

    const handleChangeNewPass = (e) => {
        setNewPassword(e.target.value)
    };

    const login = (event) => {
        event.preventDefault()
        data.aaoc_tv_redirect = aaoc_tv_redirect
        if (transferred) {
            data["code"] = code
            data["password"] = newPassword
            dispatch(loginThunk(data))
        } else {
            dispatch(loginThunk(data))
        }
    };
    const alert_message = (text) => {
        if(alert_used) return
        alert(text)
        setAlert_used(true)
    }

    const handleToast = () => {
        dispatch(hideToast())
    }

    return (
        <React.Fragment>
            <main id='login'>
                {
                    transferred
                        ?
                        <section className='section mb-4'>
                            <div className="columns is-centered">
                                <div className="column is-6">
                                    <h1 className="titleProp">
                                        <span className='title-border-white'>CAMBIAR</span> <br />
                                        <span className='title-bold-white'>CONTRASEÑA<br /> <img src={arrowTitle} alt="" width="50px" /> </span>
                                    </h1>
                                </div>
                                <form className="column is-6">
                                    <div><p style={{ color: 'yellow' }}>{message}</p></div>
                                    <div className="field">
                                        <div className="control">
                                            
                                            <Input
                                                className={"input is-medium mb-5"}
                                                type="text"
                                                placeholder="Código"
                                                value={code}
                                                handleChange={handleChangeCode}
                                                name={'code'}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control has-icons-right">
                                            <Input
                                                value={newPassword}
                                                type={'password'}
                                                placeholder={'Nueva contraseña'}
                                                handleChange={handleChangeNewPass}
                                                name={'newPass'}
                                            />

                                        </div>
                                        <div className='control is-flex is-justify-content-flex-end'>
                                           <Link to={"/forgot"}><span className="help is-light">Olvidé mi contraseña</span></Link>
                                        </div>
                                        <div className='control is-flex is-justify-content-flex-end'>
                                        <Link to={"/forgot"}><span className="help is-light">Olvidé el email con el que me registre</span></Link>  
                                        </div>
                                    </div>
                                    <div className="field is-grouped is-grouped-centered">
                                        <div className="control">
                                            <button
                                                onClick={login}
                                                className="button bg-white blue">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                    <span className="mt-5 help is-light">
                                        ¿Primera vez aquí?
                                        <Link style={{ color: "white" }} to="/register" className="yelow ml-2">
                                            Registrate ahora.
                                        </Link>
                                    </span>
                                </form>
                            </div>
                        </section>
                        
                        :
                        <section className='section mb-4'>
                            <div className="columns is-centered">
                                <div className="column is-6">
                                    <h1 className="titleProp">
                                        <span className='title-border-white'>INICIAR</span> <br />
                                        <span className='title-bold-white'>SESIÓN <br /> <img src={arrowTitle} alt="" width="50px" /> </span>
                                    </h1>
                                </div>
                                <form className="column is-6">
                                <div className='text-center'>
                                    <span className="help is-light"><strong style={{color: 'white'}}>¿Necesita ayuda?</strong> Envíe un email a consultasaaoc@gmail.com.<br /> Con gusto lo ayudaremos</span> 
                                </div>
                                    <div className="field">
                                        <div className="control">
                                            <Input
                                                className={"input is-medium mb-5"}
                                                type="text"
                                                placeholder="Email"
                                                value={data?.email}
                                                handleChange={handleChange}
                                                name={'email'}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control has-icons-right">
                                            <Input
                                                value={data?.password}
                                                type={'password'}
                                                placeholder={'Contraseña'}
                                                handleChange={handleChange}
                                                name={'password'}
                                            />

                                        </div>
                                        <div className='control is-flex is-justify-content-flex-end'>
                                        <Link to={"/forgot"}><span className="help is-light">Olvidé mi contraseña</span></Link> 
                                        </div>
                                        <div className='control is-flex is-justify-content-flex-end'>
                                        <Link to={"/forgot"}><span className="help is-light">Olvidé el email con el que me registre</span></Link>  
                                        </div>
                                 
                                        
                                        
                                    </div>
                                    <div className="field is-grouped is-grouped-centered">
                                        <div className="control">
                                            <button
                                                onClick={login}
                                                className="button bg-white blue">
                                                Iniciar sesión
                                            </button>
                                        </div>
                                    </div>
                                    <span className="mt-5 help is-light">
                                        ¿Primera vez aquí?
                                        <Link style={{ color: "white" }} to="/register" className="yelow ml-2">
                                            Registrate ahora.
                                        </Link>
                                    </span>                                    
                                </form>
                            </div>
                        </section>
                }
            </main>
            <Toast visible={toastHidden ? false : true} handleToast={handleToast} message={'Estimado usuario en caso de tener dificultades o consultas ténicas comunicarse solamente vía email a consultasaaoc@gmail.com. Su consulta será respondida en el menor tiempo posible. Muchas gracias.'} title={"Login"} />
            <Toast visible={error && true} handleToast={handleToast} message={message} title={"Login"} />
            <Backdrop show={loading} />
        </React.Fragment>
    )
}