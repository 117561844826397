import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { checkPaymentThunk } from '../../redux/activities/thunks';


export default function PaySuccess() {
    let query = useQuery();
    const dispatch = useDispatch()
    //let checkPayment = useSelector(state => state.activities.checkPayment);

    useEffect(() => {
        let paymentId = query.get("payment_id")
        if (paymentId) { 
            dispatch(checkPaymentThunk(paymentId))
        }
    }, [])

    return (
        <div
        className="titleProp"
        style={{
          fontSize: "3.4rem",
          lineHeight: "3.4rem",
          paddingTop: "10rem",
        }}
      >
        <div className={"title-bold-blue text-center"}>
          <p>SU PAGO SE REALIZADO CON ÉXITO</p>
          <p>MUCHAS GRACIAS</p>
        </div>
      </div>
    )
}


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}