import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInscription, createInscription, updateInscription } from '../../services/inscriptionsServices'

export const subscribeThunk = createAsyncThunk(
    'inscriptions/createInscriptionThunk',
    async (data, { rejectWithValue }) => {
        try {
            let req
            if (data.inscriptionId) {
                req = await updateInscription(data.data, data.inscriptionId);
            } else {
                req = await createInscription(data.data);
            }
            if (!req.success) {
                return rejectWithValue(req)
            }
            return req;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

export const getInscriptionThunk = createAsyncThunk(
    'inscriptions/getInscription',
    async (id, { rejectWithValue }) => {
        try {
            const req = await getInscription(id);
            if (!req.success) {
                return rejectWithValue(req)
            }
            return req;
        } catch (e) {
            return rejectWithValue(e)
        }
    }
);

