module.exports = {
    API_URL: process.env.REACT_APP_ENVIROMENT === "production" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_DEV,
    PUBLIC_IMG_URL: process.env.REACT_APP_PUBLIC_IMG_URL,
    AAOC_TV_URL: process.env.REACT_APP_ENVIROMENT === "production" ? process.env.REACT_APP_AAOC_TV_URL : process.env.REACT_APP_AAOC_TV_URL_DEV,
    MERCADOPAGO_URL: process.env.REACT_APP_ENVIROMENT === "production" ? process.env.REACT_APP_MERCADOPAGO_URL : process.env.REACT_APP_MERCADOPAGO_URL_DEV,
    ACTIVITY_STATUS: {
        inactive: {
            label:'Inactiva',
            type:''
        },
        pending: {
            label:'Pendiente',
            type:'info'
        },
        process: {
            label:'En Curso',
            type:'warning'
        },
        finished: {
            label:'Finalizada',
            type:'success'
        },
        cancelled: {
            label:'Cancelada',
            type:'danger'
        }
    }
}

