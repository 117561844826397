import React, {useState} from 'react'
import certificate1 from '../../assets/cert_1.png'
import { PUBLIC_IMG_URL } from '../../config/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { API_URL } from '../../config/index';
import Backdrop from "../backdrop/Backdrop"

export default function CertificateCard(props) {
    const { certificate } = props
    const [loading, setLoading] = useState(false)

    const downloadPdf = async (id) => {
        setLoading(true)
        const headers = { 'access-token': localStorage.getItem('token') };
        await fetch(`${API_URL}/activityRegistrations/downloadCertificate/${id}`, {headers})
            .then(response => {
                response.blob().then(blob => {
                    setLoading(false)
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `Certificado-AAOC.pdf`;
                    a.click();
                });
        });
    }

    return (
      <>
        <article className="column is-4">
          <div className="cont">
            <figure className="image is-fluid">
              {certificate.activity.imgPath ? (
                <img
                  src={`${PUBLIC_IMG_URL}${certificate.activity.imgPath}`}
                  alt=""
                />
              ) : (
                <img src={certificate1} alt="" />
              )}
            </figure>
            <div className="is-flex is-flex-direction-column is-justify-content-space-between px-4 pb-4">
              <h4 className="subtitle is-5 blue">
                {certificate.activity.title}
                <FontAwesomeIcon
                  style={{ marginRight: "1%" }}
                  icon={faBookmark}
                />
              </h4>
              <div
                className="control date"
                style={{ width: "90%", marginTop: "3%" }}
              >
                <span className="buttonFeatured skyBlue">
                  <FontAwesomeIcon
                    style={{ marginRight: "1%" }}
                    icon={faCalendar}
                  />
                  {Moment(certificate.activity.startDate).format("DD-MM-YYYY")}
                </span>
                <span className="buttonFeatured hourCard skyBlue">
                  <FontAwesomeIcon
                    style={{ marginRight: "1%" }}
                    icon={faClock}
                  />
                  {Moment(certificate.activity.startDate).format("h:mm:ss a")}
                </span>
              </div>
              <hr
                className="bg-gray"
                style={{
                  marginTop: "7%",
                  marginBottom: "7%",
                }}
              ></hr>
              <div className="field is-grouped is-flex is-justify-content-center">
                <p className="control">
                  <button
                    onClick={() => downloadPdf(certificate._id)}
                    className={`button bg-skyBlue white`}
                  >
                    Descargar certificado
                  </button>
                </p>
              </div>
            </div>
          </div>
        </article>
        <Backdrop show={loading} />
      </>
    );

}