import React, { useState, useEffect } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import './Backdrop.scss'

export default function Backrop({show}) {
    const [visible, setVisible] = useState(show);
    const handleClose = () => setVisible(false);

    useEffect(() => {
        setVisible(show)
    }, [show])

    return (
      <>
        <Modal id={'backdrop'} show={visible} onHide={handleClose}>
        <Spinner animation="grow" variant="dark" />
        </Modal>
      </>
    );
  }
  
