import React, { useEffect, useState } from 'react';

import "./Invoices.scss"
import moment from 'moment';
import arrowTitle from '../../assets/arrow_rigth.svg'
import arrowBtn from '../../assets/arrow_bottom.svg'
import lineBlue from '../../assets/bg_line-100.jpg'
import Table from '../../components/table/Table';
import Backdrop from "../../components/backdrop/Backdrop"
import { useDispatch, useSelector } from 'react-redux';
import { getAllBillingsThunk } from '../../redux/billings/thunks';
import { getAccountThunk } from '../../redux/user/thunks';
import { changeLoading } from '../../redux/billings/billingsSlice';
import {API_URL, MERCADOPAGO_URL} from '../../config/'
/*
import BillingSection from '../../components/billing/BillingSection'
import BillingTable from '../../components/billing/BillingTable'*/

export default function Invoices() {
    const dispatch = useDispatch()
    let invoicesSubscription = useSelector(state => state.billings.invoicesSubscription);
    let user = useSelector(state => state.user.data);
    let token = useSelector(state => state.auth.token);
    let invoicesActivities = useSelector(state => state.billings.invoicesActivities);
    let loading = useSelector(state => state.billings.loading);
    let message = useSelector(state => state.billings.message);
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [mercadopago_url, setMercadopago_url] = useState(null)
  
    useEffect(() => {
        dispatch(getAllBillingsThunk())
        dispatch(getAccountThunk())
        setMercadopago_url(MERCADOPAGO_URL + user?.subscription?.link?.replace('https://www.mercadopago.com.ar/', ''))
    }, [])

    const status_translation = (status) => {
        let statusEnEspañol;

        switch (status) {
          case 'nonMember':
            statusEnEspañol = 'No miembro';
            break;
          case 'pending':
            statusEnEspañol = 'Pendiente de pago. Su pago todavía no fue procesado';
            break;
          case 'authorized':
            statusEnEspañol = 'Miembro con cuota al día';
            break;
          case 'paused':
            statusEnEspañol = 'Membresía pausada por falta de pago';
            break;
          case 'cancelled':
            statusEnEspañol = 'Membresía cancelada';
            break;
          case 'debtor':
            statusEnEspañol = 'Deudor';
            break;
          case 'free':
            statusEnEspañol = 'Membresía gratuita';
            break;
          case 'review':
            statusEnEspañol = 'Membresia en revisión';
            break;
          default:
            statusEnEspañol = 'Estado desconocido';
        }
      
        return statusEnEspañol;
      }
    

    const downloadPdf = async (billingId) => {
        setLoadingDownload(true)
        let options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'access-token': localStorage.getItem('token')
            },
            responseType: 'arraybuffer'
        };

        await fetch(`${API_URL}/billing/downloadInvoice/${billingId}`, options)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `facturaAaoc.pdf`;
                    a.click();
                    setLoadingDownload(false)
                })
            })
        setLoadingDownload(false)
    }


    if (loading) {
        return <Backdrop show={loading} />
    }

    return (
        <main id='billing'>
            {
                user?.membershipStatus === 'authorized'
                    ?
                    <section className='section sectionSecondary bg-blue'>
                        <div className="columns is-multiline">
                            <div className="column is-12">
                                <h1 className="titleProp">
                                    <div className='text'>
                                        <span className='title-border-white'>DATOS </span>
                                        <br />
                                        <span className='title-bold-white'>DE FACTURACIÓN </span><br />
                                        <img src={lineBlue} alt="" />
                                    </div>
                                    <div className='btn-title'>
                                        <img src={arrowBtn} alt="" />
                                    </div>
                                </h1>
                            </div>

                            <div className="column is-12 ">
                                <p className='white subtitle is-4'>
                                    Tipo de membresía:  <span className='yelow' >
                                        {status_translation(user.membershipStatus)}
                                    </span>
                                </p>
                                {(user.subscription?.type === 'credit' || user.subscription?.type === 'wire') &&
                                <>
                                    <p className='white subtitle is-4'>
                                        Tipo de Pago:  <span >
                                           {user.subscription?.type === 'credit' ? 'Mercado Pago' : 'Transferencia Bancaria'}
                                        </span>
                                    </p>
                                    
                                    <p className='white subtitle is-4'>
                                        Monto de último pago: <span >
                                            {user.subscription.chargedAmount ? `${user.subscription.chargedAmount}` : 'No se ha efectuado ningún pago'}
                                        </span>
                                    </p>
                                    <p className='white subtitle is-4'>
                                        Fecha de última cobro efectuado: <span>
                                            {user.subscription.dueDate ? moment(user.subscription.dueDate).format('DD/MM/YYYY') : 'No se ha efectuado ningún pago'}
                                        </span>
                                    </p>
                                    <p className='white subtitle is-4'>
                                        Próxima fecha de pago: <span >
                                            {user.subscription.dueDate ? moment(user.subscription.dueDate).add(1, 'years').format('DD/MM/YYYY') : 'No se ha efectuado ningún pago'}
                                        </span>
                                    </p>
                                  {user.subscription?.type === 'credit' ?
                                    <p className='white subtitle is-4'>
                                        <a href={mercadopago_url} style={{color: 'white'}}>Cambiar metodo de pago, modificar, o cancelar subscripción</a>                                     
                                    </p> : null
                                    }
                                </>
                                }
                            </div>

                   

                        </div>
                    </section>
                    :
                    null
                  
            }
            {
                invoicesSubscription && invoicesSubscription.length
                    ?
                    <section className='section sectionTertiary'>
                        <div className="columns is-multiline">
                            <div className="column is-12">
                                <h1 className="titleProp">
                                    <div className='text'>
                                        <span className='title-border-blue'>FACTURAS </span>
                                        <br />
                                        <span className='title-bold-blue'>DE <span className='textLineYelow'>SUSCRIPCIONES</span></span><br />
                                    </div>
                                </h1>
                            </div>
                            <div className=" column is-12 table-container">
                                <Table vouchers={invoicesSubscription} downloadPdf={downloadPdf} />
                            </div>
                        </div>
                    </section>
                    :
                    null
            }
            {
                (invoicesActivities && invoicesActivities.length)
                    ?
                    <section className='section sectionTertiary'>
                        <div className="columns is-multiline">
                            <div className="column is-12">
                                <h1 className="titleProp">
                                    <div className='text'>
                                        <span className='title-border-blue'>FACTURAS </span>
                                        <br />
                                        <span className='title-bold-blue'>DE <span className='textLineYelow'>ACTIVIDADES</span></span><br />
                                    </div>
                                </h1>
                            </div>
                            <div className=" column is-12 table-container">
                                <Table vouchers={invoicesActivities} downloadPdf={downloadPdf} />
                            </div>
                        </div>
                    </section>
                    :
                    null
            }
            {
                (!invoicesActivities.length && !invoicesSubscription.length)
                    ?
                    <div className="titleProp" style={{fontSize:'3.4rem', lineHeight:'3.4rem', paddingTop:  user?.membershipStatus !== 'authorized' ? '10rem' : '4rem', paddingBottom: '4rem'}}>
                    <div className={'title-bold-blue text-center'}> 
                        <p>NO SE ENCONTRARON</p>
                        <p>FACTURAS GENERADAS</p>
                    </div>
                    </div>
                    :
                    null
            }
            <Backdrop show={loadingDownload} />
        </main>
    )
}