import React from 'react';
import "./ArticleModal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faCircleUser } from '@fortawesome/free-solid-svg-icons'


export default function Section1(props) {


    let {activity, closeModal } = props;
    return (
      <React.Fragment>
        <section className="modal-card-body">
          <div className="columns is-mobile">
            <div className="column is-7">
              {" "}
              <h2 className="blue" style={{ paddingBottom: "3%" }}>
                {" "}
                {activity.subtitle}
              </h2>
              <div className="field is-grouped mt-2">
                <div className="">
                  <button className="button blue is-small">
                    {activity.paid ? "EVENTO PAGO" : "EVENTO GRATIS"}
                  </button>
                </div>
                <div style={{ marginLeft: "2%" }} className="control">
                  {activity.paid ? (
                    <button className="button bg-skyBlue white is-small">
                      ${activity.price}
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="is-2 skyBlue">
                <FontAwesomeIcon icon={faLocation} />
                <span style={{ marginLeft: "2%" }}>
                  {activity.type === "virtual"
                    ? "Evento Online"
                    : activity.type === "presencial"
                    ? "Evento presencial"
                    : "Evento presencial y Online"}
                </span>
                <br />
                {props.paid && (
                  <a
                    href={activity.virtualLink}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    <span>Haga click aquí para acceder al evento virtual</span>
                  </a>
                )}

                <br />
                {activity.province !== 'undefined' && activity.address !== 'undefined' && activity.province && activity.address  && (
                <a
                  href={activity.locationLink}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                    <span>{` ${activity.province}, ${activity.address}`}</span>
                </a>
                 )}
                <div className="control my-2">
                  <button className="btn-y yelow is-small">
                    {activity?.maxParticipants === 0
                      ? `CUPOS ILIMITADOS`
                      : `CUPOS LIMITADOS A ${activity.maxParticipants} PARTICIPANTES`}
                  </button>
                </div>
              </div>
              {(activity?.doctors?.length > 0 && activity.doctors[0] !== "undefined" && activity.doctors[0] !== "") && (
                <>
                  <div
                    className="is-2 blue"
                    style={{
                      marginTop: "2%",
                      marginBottom: "-8%",
                      height: 92,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    {activity.doctors.map((doctor, index) => {
                      return (
                        <span key={index}>
                          <FontAwesomeIcon
                            style={{ marginRight: "2%" }}
                            icon={faCircleUser}
                          />
                          {doctor}
                          <br />
                        </span>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <button
            onClick={closeModal}
            className="modal-close is-large"
            aria-label="close"
          ></button> */}
        </section>
      </React.Fragment>
    );}