import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import {  getAccountThunk, subscribeThunk, updateUserThunk } from './thunks'
//import {errorHandler} from '../utils/errorHandler'
const initialState = {
  data: {},
  loading: true,
  error: false,
  eData: {},
  eMessage: null,
  message: null,
  subscribeLink: null,
  invalidToken: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    hideToast(state) {
      state.toastHidden = true
      state.error = false
    },
  },
  extraReducers: builder => {
    builder
    .addCase(getAccountThunk.fulfilled, (state, action) => { 
      console.log(action.payload.data, 'action.payload.data')
      state.data = action.payload.data; 
    })
    .addCase(subscribeThunk.fulfilled, (state, action) => { 
      state.subscribeLink = action.payload.link;
    })
    .addCase(updateUserThunk.fulfilled, (state, action) => { 
      state.data = action.payload.user;
      state.showToast = true
      state.message = 'Los datos se han cambiado correctamente';
    })
    .addCase(updateUserThunk.rejected, (state, action) => { 
      state.message = 'Ha ocurrido un error, por favor intenta nuevamente'
      state.showToast = true
    })

    .addMatcher( 
      isPending(getAccountThunk, subscribeThunk, updateUserThunk),
      (state) => {
        state.loading = true;
        state.error = false;
        state.eData = {};
        state.success = null
        state.message = null;
        state.eMessage = null;
      }
    )

 
    .addMatcher(
      isFulfilled(getAccountThunk, subscribeThunk, updateUserThunk),
      (state, action) => {
        state.loading = false;
        state.error = false;
        state.eData = {};
        state.success = action.payload.success
        state.eMessage = null;
      }
    )


   .addMatcher(
      isRejectedWithValue(getAccountThunk, updateUserThunk),
      (state, action) => {
       // errorHandler(action.payload)
        state.loading = false;
        state.error = true;
        state.success = false;
        state.eData = action.payload;
        state.eMessage = 'Ha ocurrido un error, por favor intente nuevamente en unos momentos';
        state.token = null;
        localStorage.removeItem("token");
      }
    )
    
   .addMatcher(
    isRejectedWithValue(subscribeThunk),
    (state, action) => {
     // errorHandler(action.payload)
     console.log(action.payload, 'action.payload')
      state.loading = false;
      state.error = true;
      state.success = false;
      state.eData = action.payload;
      state.eMessage = action.message;
    }
  )
    
  }
});

export const { hideToast } = userSlice.actions;
export default userSlice.reducer;
