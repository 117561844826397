import { createAsyncThunk } from '@reduxjs/toolkit';
import { allActivitiesService, myActivitiesService, activitiySubscribeService, checkPaymentService } from '../../services/activitiesServices'

export const allActivitiesThunk = createAsyncThunk(
  'activities/allActivities',
  async (data, { rejectWithValue }) => {
    try {
      const req = await allActivitiesService();
      if (req.success === false) {
        return rejectWithValue(req)
      }
      return req;
    } catch (e) {
      return rejectWithValue(e)
    }
  }
);

export const myActivitiesThunk = createAsyncThunk(
  'activities/myActivities',
  async (data, { rejectWithValue }) => {
    try {
      const req = await myActivitiesService();
      if (req.success === false) {
        return rejectWithValue(req)
      }
      return req;
    } catch (e) {
      return rejectWithValue(e)
    }
  }
);


export const activitySubscribeThunk = createAsyncThunk(
  'activities/activitiesSubscribeThunk',
  async (data, { rejectWithValue }) => {
    try {
      const req = await activitiySubscribeService(data);
      if (req.success === false) {
        return rejectWithValue(req)
      }
      return req;
    } catch (e) {
      return rejectWithValue(e)
    }
  }
);



export const checkPaymentThunk = createAsyncThunk(
  'activities/checkPaymentThunk',
  async (data, { rejectWithValue }) => {
    try {
      const req = await checkPaymentService(data);
      if (req.success === false) {
        return rejectWithValue(req)
      }
      return req;
    } catch (e) {
      return rejectWithValue(e)
    }
  }
);


