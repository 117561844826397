import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCertsByUserService } from '../../services/certificatesService'

export const getAllCertificatesThunk = createAsyncThunk(
  'certificates/allCertificates',
  async (data, { rejectWithValue }) => {
    try {
      const req = await getCertsByUserService();
     
      if(req.status !== 200){
        return rejectWithValue(req.data)
      }
      if (!req.data.success) { 
         return rejectWithValue(req.data)
      }
      return req;
    } catch (e) {
      return rejectWithValue(e)
    }
  }
);
