
import React from 'react';
import { Link } from 'react-router-dom';
import Backdrop from "../../components/backdrop/Backdrop";
import moment from "moment"
export default function Table(props) {


    return (
        <>
            <Backdrop show={props.downloading} />
            <table className="table is-striped is-hoverable is-fullwidth">
                <thead className='bgBlue'>
                    <tr>
                        <th>Código de comprobante</th>
                        <th>Importe</th>
                       
                        <th>Estado</th>
                        <th>Fecha de Emisión</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !props.vouchers
                            ?
                            null
                            :
                            props.vouchers.map((voucher, index) =>
                                <tr>
                                    <td className={'bg-lig'}>{voucher.code}</td>
                                    <td className={'bg-lig'}>${voucher.amount_charged}</td>
                                    
                                    <td className={'bg-lig'}>{voucher.description}</td>
                                    <td className={'bg-lig'}>{moment(voucher.createdAt).format('DD/MM/YYYY')}</td>
                                    <td className={'bg-lig'}>
                                        {
                                            voucher.hf_id ?
                                                <React.Fragment>
                                                    <button
                                                        onClick={() => props.downloadPdf(voucher._id)}
                                                        style={{ marginTop: 0 }}
                                                    >
                                                        Descargar Factura
                                                    </button>
                                                    <i
                                                        className={`fa-solid fa-print}`}></i>
                                                </React.Fragment>
                                                :
                                                "Procesando"
                                        }
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </table>
        </>
    )
}