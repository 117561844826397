import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { getOneActivityThunk } from './thunks'

const initialState = {
  activity: {},
  activityRegistration: {},
  myActivities: [],
  error: false,
  eDescription: {},
  subscribeActivityRefresh: false,
  checkPayment: null,
  message: ""
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    hideToast(state) {
      state.message = ''
      state.showToast = false
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getOneActivityThunk.fulfilled, (state, action) => { 
      state.loading = false;
      state.activity = action.payload.activity;
      state.activityRegistration = action.payload.activityRegistration;
      state.myActivities = action.payload.myActivities;
      state.message = action.payload.message
      state.error = false
      state.eDescription = {}
      // state.showToast = true
    })

    .addMatcher(
        isPending(
          getOneActivityThunk
        ),
        (state) => {
          state.loading = true;
          state.error = false;
          state.eDescription = {};
        //  state.message = ""
        }
      )

      .addMatcher(
        isRejectedWithValue(
          getOneActivityThunk),
        (state, action) => {
          state.activity = null
          state.loading = false;
          state.error = true;
          state.eDescription = action.payload;
          state.message = action.payload.message
        }
      );
  },
});

export const { hideToast } = activitySlice.actions;
export default activitySlice.reducer;

