import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import siteDataReducer from './siteData/siteDataSlice';
import userReducer from './user/userSlice';
import activitiesReducer from './activities/activitiesSlice';
import certificatesReducer from './certificates/certificatesSlice';
import billingsReducer from './billings/billingsSlice';
import inscriptionsReducer from './inscriptions/inscriptionsSlice';
import pop_upsSlice from './pop_ups/pop_upsSlice';
import activityReducer from './activity/activitySlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    siteData: siteDataReducer,
    user: userReducer,
    activities: activitiesReducer,
    activity: activityReducer,
    certificates: certificatesReducer,
    billings: billingsReducer,
    inscriptions: inscriptionsReducer,
    pop_up: pop_upsSlice
  },
});
