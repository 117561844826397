import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
import { loginThunk, registerThunk, forgotThunk, resetThunk } from './thunks'
import {  getAccountThunk } from '../user/thunks'

const initialState = {
  data: {},
  aaoc_tv_redirect: false,
  token: localStorage.getItem('token') ?  localStorage.getItem('token') : null,
  error: false,
  message: "",
  eDescription: {},
  transferred: false,
  reset: false,
  toastHidden: false,
  lastVisit: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut(state) {
      localStorage.removeItem('token');
      state.token = null
      state.data = {}
      state.aaoc_tv_redirect = false
    },
    setToken(state, action) {
      state.token = action.payload
    },
    hideToast(state) {
      state.toastHidden = true
      state.error = false
    },
    navigation(state, action) {
      state.lastVisit = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(forgotThunk.fulfilled, (state, action) => { 
      state.reset = true;
      state.loading = false;
      state.error = false;
      state.message = action.payload.data.message;
    })
    .addCase(resetThunk.fulfilled, (state, action) => { 
      state.reset = false;
      state.loading = false;
      state.error = false;
      state.data = action.payload.data.data.user;
      state.token = action.payload.data.token;
      localStorage.setItem("token", action.payload.data.token);
    })
      .addMatcher(
        isPending(
          loginThunk,
          registerThunk,
          forgotThunk
        ),
        (state) => {
          state.loading = true;
          state.error = false;
          state.message = "";
          state.aaoc_tv_redirect = false
        }
      )

      .addMatcher(
        isFulfilled(loginThunk, registerThunk),
        (state, action) => {
          state.loading = false;
          state.error = false;
          state.message = action.payload.data.message;
          state.aaoc_tv_redirect = action.payload.data.aaoc_tv_redirect;
          if(action.payload.data?.transferred){
            state.transferred = true
          }else{
            state.transferred = false
            state.data = action.payload.data.data.user;
            state.token = action.payload.data.token;
            localStorage.setItem("token", action.payload.data.token);
          }
  
        }
      )

      .addMatcher(
        isRejectedWithValue(
          loginThunk,
          registerThunk,
          forgotThunk,
          resetThunk,
          getAccountThunk
        ),
        (state, action) => {
          state.loading = false;
          state.aaoc_tv_redirect = false
          if (action.payload?.response?.data?.error === 'Access denied') {
            state.error = false
          } else {
            state.error = true;
          }      
          state.user = {};
          state.token = null;
          localStorage.removeItem("token");
          state.message = action.payload?.response?.status === 401 ? 'Su sesión ha expirado' : action.payload.message;
        }
      )
  },
});

export const { logOut, hideToast, navigation, setToken } = authSlice.actions;
export default authSlice.reducer;

