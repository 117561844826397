import React from 'react'
import { ToastContainer, Toast } from 'react-bootstrap';
import './Toast.scss'

export default function ToastComponent({ visible, handleToast, message, title }) {

    return (
        <ToastContainer className="p-3" position={'top-center'} containerPosition={'fixed'}>
            <Toast  show={visible ? true : false} onClose={handleToast}  delay={5000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{title}</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}