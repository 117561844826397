import axios from "axios";
import { authHeader } from './utils'
import {API_URL} from '../config'


export const getPopup = async () => {
  try {
    let options = {
      headers: {
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${API_URL}/pop_ups/getOne`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

