import axios from "axios";
import { authHeader } from './utils'
import {API_URL} from '../config'


export const getCertsByUserService = async () => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${API_URL}/activityRegistrations/allByUser`,
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};
