import React from 'react';
import { useLocation } from "react-router-dom"
import "./PayPending.scss"

export default function PayPending(props) {
    let query = useQuery();

    return (
        <div
        className="titleProp"
        style={{
          fontSize: "3.4rem",
          lineHeight: "3.4rem",
          paddingTop: "10rem",
        }}
      >
        <div className={"title-bold-blue text-center"}>
          <p>SU PAGO SE ENCUENTRA EN REVISIÓN</p>
          <p>POR FAVOR VUELVA MAS TARDE</p>
        </div>
      </div>
    )
}


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}