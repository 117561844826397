import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.svg'
import './Navbar.scss'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from "../backdrop/Backdrop"
import { logOut } from '../../redux/auth/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { AAOC_TV_URL } from '../../config';
import { getAccountThunk } from '../../redux/user/thunks';


export default function Navbar() {
    const [user_token, setUserToken] = useState(localStorage.getItem('token'))
    const [aaoc_tv_url, setAaoc_tv_url] = useState(AAOC_TV_URL + user_token)
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    let userData = useSelector(state => state.user.data);
    let loading = useSelector(state => state.user.loading);
    let subscribeLink = useSelector(state => state.user.subscribeLink);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const redirect = () => {
        //redirect to url
         window.location.assign(aaoc_tv_url);
        console.log('redirecting to: ', aaoc_tv_url)
    }

    useEffect(() => {
        setUserToken(localStorage.getItem('token'))
        if (user_token) dispatch(getAccountThunk())
        setAaoc_tv_url(AAOC_TV_URL + user_token)
    }, [])


    let navbarOptions = [
      {
        name: "Inicio",
        link: "/",
      } /*,
        {
            name: 'Mi Perfil',
            link: '/profile'
        }*/,
      {
        name: "Actividades",
        link: "/activities",
      },
      {
        name: "Mis Actividades",
        link: "/myActivities",
      },
      {
        name: "AAOC TV",
        link: aaoc_tv_url,
        external: true,
      },
    ];


    if (!userData?._id) {
        navbarOptions = navbarOptions.filter(option => option.name !== "Mis Actividades");
    }

    const openMobileMenu = () => {
        if (showMobileMenu) {
            return setShowMobileMenu(false)
        }
        setShowMobileMenu(true)
    }

    const closeMobileMenu = () => {
        setShowMobileMenu(false)
    }

    const navigateTo = (path) => {
        setShowMobileMenu(false)
        return navigate(path)
    }

    const logout = () => {
        setShowMobileMenu(false)
    }

    const onLogout = () => {
        dispatch(logOut())
        localStorage.removeItem('token');
        navigate('/login')
    }

    const prettyfy_membershiStatus = (status) => {
        switch (status) { 
            case 'nonMember':
            case 'paused':
            case 'cancelled':
            case 'free':    
                return 'No socio'
            case 'authorized':
                return 'Socio'
            case 'pending': 
            case 'debtor':
                return 'Pendiente de pago'  
            case 'review':
                return 'En revisión' 
            default:
                return ''
        }
    }

    const payment_button = () => {
        if (userData?.membershipStatus !== 'authorized' && userData?.membershipStatus !== 'review' && userData?.membershipStatus) {
            return <Link to="/join"><button style={{ color: 'white', fontSize: '16px' }} className='button bg-blue' >
            Pagar membresía
        </button></Link>
        }
    }

    return (
        <header id="menuNav" style={{ width: '100%' }} className="">
            <div id='mobileMenu' style={{ display: showMobileMenu ? 'block' : 'none' }} onClick={closeMobileMenu}>
                <div style={{paddingLeft:'8px'}}>
                    {userData?._id ?
                    <div style={{paddingBottom: '10px'}}>
                        {/* <span className="icon mr-1">
                            <i className='fa-regular fa-circle-user white'></i>
                        </span> */}
                        <p style={{paddingTop: '8px', color: 'white'}}>{userData.name} ({prettyfy_membershiStatus(userData?.membershipStatus)})</p>
                        {payment_button()}
                    </div>
                    :
                    null
                    }
                    <div>
                    {userData?._id ? <>
                        <p ><Link style={{color: 'white'}} to="/profile">Mi perfil</Link></p>
                        <p ><Link style={{color: 'white'}} to="/myActivities">Mis actividades</Link></p>
                        <p ><Link style={{color: 'white'}} to="/invoices">Facturación</Link></p>
                        <p ><Link style={{color: 'white'}} to="/certificates">Certificados</Link></p>
                        <p style={{color: 'white'}}><a onClick={redirect} >AAOC TV</a></p>
                        <p style={{color: 'white'}} onClick={onLogout}>Cerrar sesion</p>
                        </>:
                      <>
                      <p ><Link style={{color: 'white'}} to="/activities">Actividades</Link></p>
                      <p><Link style={{color: 'white'}} to="/login">Iniciar sesión</Link></p>
                      </>
                    }
                    </div>
                </div>
            </div>
            <nav className="navbar py_5" role="navigation" aria-label="main navigation" >
                <div className="navbar-brand ml-2 ">
                    <Link to="/" className="navbar-item">
                        <img src={Logo} alt='logo' />
                    </Link>
                    <div role="button" className="navbar-burger" data-target="navMenu" aria-label="menu" aria-expanded="false" onClick={openMobileMenu}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    
                    {/* <p style={{paddingLeft:'42px', paddingTop: '8px'}}>Menu</p> */}
                    </div>
                </div>

                <div className="navbar-menu mr-2" id="navMenu">
                    <div className="navbar-end py-1">
                        {
                            payment_button()
                        }
                        {
                            navbarOptions.map((option, index) => {
                                if (option.external) {
                                    return <a onClick={redirect} className="navbar-item" key={index}>
                                    <span>{option.name}</span>
                                    </a>
                                }
                                return <Link to={option.link} className="navbar-item" key={index}>
                                    <span>{option.name}</span>
                                </Link>
                            })
                        }
                        <div id='userItem' className="navbar-item bg-blue dropdown">
                            {userData?._id ?
                            <>
                            <span className="icon-text dropbtn has-text-centered" style={{cursor: 'pointer'}}>
                                <span className="icon mr-1">
                                    <FontAwesomeIcon color='white' icon={faCircleUser} />
                                </span>
                                <span className='white mb-1' style={{fontSize: '12px'}}>{userData.name} ({prettyfy_membershiStatus(userData?.membershipStatus)})</span>
                            </span>
                            <div className="dropdown-content" style={{marginLeft: -16}}>
                                <Link to="/profile">Mi Perfil</Link>
                                <Link to="/invoices">Facturación</Link>
                                <Link to="/certificates">Certificados</Link>
                                <a style={{color: 'black'}} onClick={onLogout} className="navbar-item">
                                    Cerrar sesion
                                </a>
                            </div>
                            </>
                            :
                            <Link to="/login" >
                                <span className='white mb-1' style={{fontSize: '12px'}}>Iniciar sesión</span>
                            </Link>
                        }
                        </div>
                    </div>
                </div>
            </nav>
            <Backdrop show={userData & loading} />
        </header>
    )

}