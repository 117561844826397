import React, { useState} from 'react';
import { PUBLIC_IMG_URL } from '../../config/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import ArticleModal from "../molecules/articleModal/ArticleModal"


export default function FeaturedActivity(props) {
    const { featuredActivity, userActivityFeatured, setActivitySelected } = props
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const [section, setSection] = useState(1)
    const [show_other, set_show_other] = useState(false);
    const [data, setData] = useState({});

    const handleChange = (e) => {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
        e.target.name === "dietary_restrictions" &&
          (e.target.value === "Otro"
            ? set_show_other(true)
            : set_show_other(false));
      };

      const closeModal = () => {
        setIsVisibleModal(false)
    }

    const showMore = () => {
        setIsVisibleModal(true)
    }

    const suscribeToActivity = () => {
        closeModal()
        setActivitySelected({...featuredActivity, ...data})
    }

    
    return (
      <React.Fragment>
        <div
          id="home"
          className={
            props.adminPreview ? "post column is-12" : "post column is-6"
          }
        >
          <p className="blue subtitle is-4">Actividad destacada</p>

          {/* <img src={`${API_URL}/public/img/${featuredActivity.imgPath}`} alt="" />*/}
          <img
            className="imgFeaturedActivity"
            src={`${PUBLIC_IMG_URL}${featuredActivity.imgPath}`}
            alt=""
          />

          <div style={{ width: "100%" }} className="bg-blue p-5">
            <h3 className="subtitle is-5 has-text-white">
              {featuredActivity.title}
            </h3>
            <p className="white">{featuredActivity.description}</p>
            <p className="white">
              {featuredActivity.paid
                ? "Evento Pago $" + featuredActivity.price
                : "Evento gratis"}
            </p>
            <div
              id="btnPost"
              className="field is-grouped is-justify-content-space-between"
            >
              <div style={{ width: "40%" }} className="control date">
                <span className="buttonFeatured yellow">
                  <FontAwesomeIcon
                    style={{ marginRight: "2%" }}
                    icon={faCalendar}
                  />
                  {Moment(featuredActivity.startDate).format("DD-MM-YYYY")}
                </span>
                <span
                  className="buttonFeatured yellow"
                  style={{ marginLeft: "5%" }}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "2%" }}
                    icon={faClock}
                  />
                  {Moment(featuredActivity.startDate).format("h:mm:ss a")}
                </span>
              </div>
              {userActivityFeatured ? (
                <span className="control btn-inscribirse">
                  <span disabled className="button is-normal bg-white">
                    <span className="blue">Inscripto</span>
                  </span>
                </span>
              ) : (
                <span
                  onClick={showMore}
                  className="control btn-inscribirse"
                >
                  <span className="button is-normal bg-white">
                    <span className="blue">Inscribirme</span>
                  </span>
                </span>
              )}
            </div>
            <ArticleModal
              isSuscribed={userActivityFeatured}
              suscribeToActivity={suscribeToActivity}
              activity={featuredActivity}
              isVisibleModal={isVisibleModal}
              closeModal={closeModal}
              setSection={setSection}
              section={section}
              handleChange={handleChange}
              how_other={show_other}
              set_show_other={set_show_other}
            />
          </div>
        </div>
      </React.Fragment>
    );
}