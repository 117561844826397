import React, {useState} from 'react';
import "./ArticleModal.scss"
import Section1 from './Section1';
import Section2 from './Section2';
import Footer from './Footer';
import Backdrop from "../../backdrop/Backdrop"
import { PUBLIC_IMG_URL } from '../../../config/index';

export default function ArticleModal(props) {

  const [sub_type_state, set_sub_type_state] = useState(null);


  let {activity, closeModal, section, setSection, handleChange, show_other, set_show_other } = props;

  const style = {};
  if (props.loading) {
    style.display = "none";
  }

    return (
      <div className={props.isVisibleModal ? (!closeModal ? 'modal is-active relative-card' : 'modal is-active') : 'modal'} >
       
        <div className="modal-background" onClick={closeModal ? closeModal : undefined}>
          {props.loading && <Backdrop show={true} />}
        </div>
        
        
        <div className="modal-card" style={style}>
        
        <header className="modal-card-head activity" 
        // style={{backgroundImage: `url('${PUBLIC_IMG_URL}${activity.imgPath}')`}}
        >
          
                <p className="modal-card-title bg-skyBlue">
                  {activity.dateText}
                </p>{" "}
                <br />
                <h1 className="title-bold-white is-centered">
                  {" "}
                  {activity.title}{" "}
                </h1>
              </header>
        {section === 1 && Section1(props)}
        {section === 2 && Section2({...props, show_other, set_show_other, handleChange, sub_type_state})}
        {Footer({...props, section, setSection, set_sub_type_state, sub_type_state})}
        </div>
      </div>
    );
}




