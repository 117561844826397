import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Backdrop from "../../components/backdrop/Backdrop";
import { subscribeThunk } from "../../redux/user/thunks";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../components/modal/Modal";
import { getAccountThunk } from '../../redux/user/thunks';
import Toast from "../../components/toast/Toast";
import { hideToast } from "../../redux/user/userSlice";

export default function Activity() {
  let userData = useSelector((state) => state.user.data);
  const error = useSelector(state => state.user.error);
  const errorMessage = useSelector(state => state.user.eData?.message);
  let loading = useSelector((state) => state.user.loading);
  let subscribeLink = useSelector(state => state.user.subscribeLink);
  const dispatch = useDispatch();
  const [loadingAval, setLoadingAval] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [aval1, setAval1] = useState();
  const [errorAval1, setErrorAval1] = useState(false);
  const [aval2, setAval2] = useState();
  const [errorAval2, setErrorAval2] = useState(false);
  const [showWireDetails, setShowWireDetails] = useState(false);

  console.log(errorMessage)
  

  const handleToast = () => {
    dispatch(hideToast())
}

  const onSubscribe = (member) => {
    if (userData.aval1.trim() && userData.aval2.trim()) {
      dispatch(
        subscribeThunk({ aval1: userData.aval1, aval2: userData.aval2 })
      );
    } else {
      setModalShow(true);
    }
  };

  const onSubscriptionContinue = () => {
    window.location.replace(userData.subscription.link)
  }

  useEffect(() => {
    dispatch(getAccountThunk())
}, [])

useEffect(() => {
  if (subscribeLink) {
      setLoadingAval(true)
      window.location.replace(subscribeLink)
  }
}, [subscribeLink])


const saveAval = () => {
  // Elimina espacios en blanco al principio y al final de aval1 y aval2
  const trimmedAval1 = aval1.trim();
  const trimmedAval2 = aval2.trim();

  if (trimmedAval1 && trimmedAval2) {
    setModalShow(false);
    setErrorAval1(false);
    setErrorAval2(false);

    dispatch(subscribeThunk({ aval1: trimmedAval1, aval2: trimmedAval2 }));
  } else {
    if (!trimmedAval1) {
      setErrorAval1(true);
    } 
    if (!trimmedAval2) {
      setErrorAval2(true);
    }
  }
};

  const handleCloseModal = () => {
    setModalShow(false);
    setErrorAval1(false);
    setErrorAval2(false);
  };
  if (loading || loadingAval) {
    return <Backdrop show={true} />;
  }

  if (userData.membershipStatus === 'authorized' ) {
    return (
      <div className="container" style={{marginTop: '10vh'}}>
         <div
            className={"title-bold-blue text-center"}
            style={{
              fontSize: "3rem",
              lineHeight: "3rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
          >
            <p>Su subscripción se encuentra actualmente activa.</p>
          </div>
      </div>
    )
  } 

  return (
    <div className="container" style={{marginTop: '10vh'}}>
      <div className="columns is-justify-content-center">
        <div className="column is-8">
          <div
            className={"title-bold-blue text-center"}
            style={{
              fontSize: "3rem",
              lineHeight: "3rem",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
          >
            <p>Pagar membresía</p>
          </div>
          <div
            style={{ fontSize: "1rem", paddingBottom: "1rem" }}
          >
            <p>
              Estimado/a recuerde que la cuota actual es de 25.000 y se paga de
              forma anual. La subscripción incluye acceso a todo el contenido de
              AAOC TV y a eventos exclusivos.
            </p>
          </div>
          <div
            className="buttons is-justify-content-center"
            style={{ display: "flex", gap: "1rem", paddingTop: "1rem" }}
          >
            <button
              onClick={
                 onSubscribe
              }
              style={{ color: "white", fontSize: "16px" }}
              className="button bg-blue"
            >
              Mercado Pago
            </button>
            <button
              onClick={setShowWireDetails}
              style={{ color: "white", fontSize: "16px" }}
              className="button bg-blue"
            >
              Transferencia Bancaria
            </button>
          </div>
        </div>
      </div>
      <div
        className="columns is-justify-content-center"
        style={{ display: showWireDetails ? "flex" : "none" }}
      >
        <div className="column is-8">
          <div
            className={"title-bold-blue text-center"}
            style={{
              fontSize: "2rem",
              lineHeight: "3.4rem",
              paddingTop: "2rem",
            }}
          >
            <p>Datos de transferencia</p>
          </div>
          <div
            style={{ fontSize: "1rem" }}
          >
            <p
              style={{
                fontSize: "1rem",
                lineHeight: "1rem",
              }}
            >
              Por favor al realizar la transferencia enviar el comprobante junto
              con su nombre y apellido a consultasaaoc@gmail.com. Será
              notificado por mail una vez que haya sido dado de alta.
            </p>
            <p>
              <br />
              Titular: ASOCIACION ARGENTINA DE ONCOLOGIA CLINICA
              <br />
              CUIT: 30650422496
              <br />
              Banco: Banco Galicia
              <br />
              Cuenta Corriente en Pesos N°: 00011144-5 128-5
              <br />
              CBU: 0070128620000011144559
              <br />
            </p>
          </div>
        </div>
      </div>

      <Modal
        value1={aval1}
        setValue1={setAval1}
        value2={aval2}
        setValue2={setAval2}
        show={modalShow}
        onHide={handleCloseModal}
        submit={saveAval}
        error1={errorAval1}
        error2={errorAval2}
        errorMsg={"Debe ingresar un aval"}
      />
      <Toast visible={error ? true : false} handleToast={handleToast} message={errorMessage} title={"Error"} />
    </div>
  );
}
