import { Outlet, useLocation } from 'react-router';
import { navigation} from '../../redux/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import React, { useEffect } from 'react';

export default function Navigation() {
  let user = useSelector(state => state.user.data);
  let loggedIn = useSelector(state => state.auth.token);
  let redirect = useSelector(state => state.auth.lastVisit);
  const dispatch = useDispatch()
  const location = useLocation();

  useEffect(() => {
    if (!loggedIn || !user._id) {
    dispatch(navigation(location.pathname));
    } else {
      dispatch(navigation(null));
    }
  }, [location, dispatch]);
  
    return (
      <>
      {(loggedIn & redirect) ? <Navigate to={redirect}  /> : null}
      <Outlet />
      </>
    )
}


