import React, { useState, useEffect } from 'react'
import certificate1 from '../../assets/cert_1.png'
import ArticleModal from "../molecules/articleModal/ArticleModal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faBuilding } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment'
import { PUBLIC_IMG_URL } from '../../config/index';
import './ActivityCard.scss'
import { useDispatch, useSelector } from 'react-redux';
import { AAOC_TV_URL } from '../../config';
import Backdrop from "../backdrop/Backdrop"
import Toast from "../toast/Toast"
import { hideToast} from '../../redux/inscriptions/inscriptionsSlice';

export default function ActivityCard({ isMember, activity, show, isMembership, userData, activitySelected, setActivitySelected, myActivities }) {
    const dispatch = useDispatch()
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const [section, setSection] = useState(1)
    const [show_other, set_show_other] = useState(false);
    const [virtualLink, setVirtualLink] = useState(null)
    const [data, setData] = useState({});
    const [user_token, setUserToken] = useState(localStorage.getItem('token'))
    const [aaoc_tv_url, setAaoc_tv_url] = useState('https' + user_token)
    

   const redirect = (e) => {
    window.location.replace(activity.virtualLink);
   }


    const closeModal = () => {
        setIsVisibleModal(false)
        setSection(1)
    }


    const showModal = () => {
        setIsVisibleModal(true)
    }

    const suscribeToActivity = (activity) => {
            setActivitySelected({...activity, ...data})
            closeModal()
    }

    const handleChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
      e.target.name === "dietary_restrictions" &&
        (e.target.value === "Otro"
          ? set_show_other(true)
          : set_show_other(false));
    };



    const handleToast = () => {
        dispatch(hideToast())
    }

    return (
      <React.Fragment>
        {show ? (
          <React.Fragment>
            <ArticleModal
              activity={activity}
              isSuscribed={activity.continueSubscription ? false : myActivities}
              closeModal={closeModal}
              isVisibleModal={isVisibleModal}
              suscribeToActivity={suscribeToActivity}
              setSection={setSection}
              section={section}
              handleChange={handleChange}
              show_other={show_other}
              set_show_other={set_show_other}
              isMember={isMember}
            />

            <article className="column is-4">
              <div className="cont">
                <div className="containerLabels">
                  <div className="modalityLabel">
                    {activity.type ? activity.type.toUpperCase() : null}
                  </div>
                  {activity.continueSubscription && (
                    <div className="notPaymentLabel">No pago</div>
                  )}
                  {activity.paid ? (
                    <div className="priceLabel">${activity.price}</div>
                  ) : null}
                </div>

                <img
                  className="imageCard"
                  src={`${PUBLIC_IMG_URL}${activity.imgPath}`}
                  alt=""
                />

                <div
                  className={`contentCard is-flex is-flex-direction-column is-justify-content-space-between px-4 pb-4 ${
                    myActivities ? "bg-blue" : "bg-white"
                  }`}
                >
                  <h4
                    className={`subtitle is-5 ${
                      myActivities ? "white" : "blue"
                    }`}
                  >
                    {activity?.title}
                  </h4>
                  {(activity?.description && activity?.description !== 'undefined') && (
                      <p
                      className={` descriptionCard ${
                        myActivities ? "white" : "blue"
                      }`}
                    >
                      {activity?.description}
                    </p>
                  )}
                

                  <div className="control date">
                    <span
                      className={`buttonFeatured ${
                        myActivities ? "yellow" : "skyBlue"
                      }`}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: "1%" }}
                        icon={faCalendar}
                      />
                      {Moment(activity.startDate).format("DD-MM-YYYY")}
                    </span>
                    <span
                      className={`buttonFeatured hourCard ${
                        myActivities ? "yellow" : "skyBlue"
                      }`}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: "1%" }}
                        icon={faClock}
                      />
                      {Moment(activity.startDate).format("h:mm:ss a")}
                    </span>
                  </div>
                  {(activity.address && activity.address !== 'undefined') && (
                    <div className="control date">
                      <span
                        className={`buttonFeatured ${
                          myActivities ? "yellow" : "skyBlue"
                        }`}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: "1%" }}
                          icon={faBuilding}
                        />
                        {activity.address}
                      </span>
                    </div>
                  )}

                  <hr className="bg-gray"></hr>
                  <div className="field is-flex is-justify-content-center">
                    <div className="control">
                      {activity.virtualLink && activity.type !== 'presencial' && myActivities && (
                        <button
                          onClick={redirect}
                          className={`btnSubscription button blue`}
                        >
                          Acceder a la actividad
                        </button>
                      )}
                      <button
                        onClick={showModal}
                        className={`btnSubscription button ${
                          myActivities ? "blue" : "bg-blue white"
                        }`}
                      >
                        {myActivities
                          ? activity.continueSubscription
                            ? "Continuar compra"
                            : "Ver más"
                          : "Inscribirme"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );

}