import React, { useState } from "react";
//import { Container, Row, Col } from "react-bootstrap";
//import SideBar from "../../components/navbars/Sidebar";
import Navbar from "../components/navbar/Navbar";
export default function LayoutNavbar(props) {
  return (
    <>
      <Navbar />
      {props.page}
    </>
  );
}
