import React, { useState, useEffect } from 'react';
import ArticleModal from "../../components/molecules/articleModal/ArticleModal"
import { useParams } from "react-router-dom";
import Backdrop from "../../components/backdrop/Backdrop"
import { useDispatch, useSelector } from 'react-redux';
import { allActivitiesThunk, activitySubscribeThunk } from '../../redux/activities/thunks';
import { getOneActivityThunk} from '../../redux/activity/thunks';

export default function Activity() {

  const dispatch = useDispatch()
     const { id } = useParams();
    let activity = useSelector(state => state.activity.activity);
    let myActivities = useSelector(state => state.activity.myActivities);
    let loading = useSelector(state => state.activity.loading);
    let userData = useSelector(state => state.user.data);
    const [activitySelected, setActivitySelected] = useState()
    const [section, setSection] = useState(1);
    const [isVisibleModal, setIsVisibleModal] = useState(false)

    const continueSubscription = myActivities?.length && myActivities.find(elem => elem.activity.toString() === activity?._id.toString())?.status === 'approved'


    const suscribeToActivity = (activity) => {
      // setActivitySelected({...activity, ...data})
    }

    const closeModal = () => {
      setIsVisibleModal(false)
      setSection(1)
  }

    useEffect(() => {
      if (activitySelected) {
          dispatch(activitySubscribeThunk(activitySelected))
      }
      }, [activitySelected])

    useEffect(() => {
      dispatch(getOneActivityThunk(id))
    }, [])

    const handleChange = (e) => {
      // setData({
      //   ...data,
      //   [e.target.name]: e.target.value,
      // });
      // e.target.name === "dietary_restrictions" &&
      //   (e.target.value === "Otro"
      //     ? set_show_other(true)
      //     : set_show_other(false));
    };

    console.log(activity)
 return (
  !activity && !loading ? (
    <div className="titleProp" style={{fontSize:'3.4rem', lineHeight:'3.4rem', paddingTop: '10rem'}}>
        <div className={'title-bold-blue text-center'}> 
             <p>Actividad no encontrada</p>
        </div>
    </div>  
  ) : (
  <ArticleModal
  activity={activity}
  isSuscribed={continueSubscription}
  isVisibleModal={true}
  suscribeToActivity={suscribeToActivity}
  setSection={setSection}
  section={section}
  handleChange={handleChange}
  closeModal = {false}
  isMember={userData?.membershipStatus === 'authorized' ? true : false}
  loading={loading}
  // show_other={show_other}
  // set_show_other={set_show_other}
/>
 )   )
}