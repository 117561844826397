import axios from "axios";
import { authHeader } from './utils'
import {API_URL} from '../config'

export const createInscription = async (data) => {
    try {
        let options = {
            headers: {
                ...authHeader()
            }
        };
        const response = await axios.post(
            `${API_URL}/inscriptions/create`,
            data,
            options
        );
        return response.data;
    } catch (e) {
        return e;
    }
};


export const getInscription = async (id) => {
    try {
        let options = {
            headers: {
                "content-type": "application/json",
                ...authHeader()
            },
        };
        const response = await axios.get(
            `${API_URL}/inscriptions/getOneByUser/${id}`,
            options
        );

        return response.data;
    } catch (e) {
        return e;
    }
};


export const updateInscription = async (data, id) => {
    try {
        let options = {
            headers: {
                ...authHeader()
            },
        };
        const response = await axios.put(
            `${API_URL}/inscriptions/update/${id}`,
            data,
            options
        );
        return response.data;
    } catch (e) {
        return e;
    }
};