
import axios from "axios";
import {API_URL} from '../config'

export const register = async (data) => {
  try {
    let options = {
      headers: {
        //"content-type": "application/json",
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`
      },
    };
    const response = await axios.post(
      `${API_URL}/register`,
      data,
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const login = async (data) => {
  try {
    let response = await axios.post(
      `${API_URL}/login`,
      JSON.stringify(data),
      { headers: { "content-type": "application/json" } }
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const forgot = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/forgot`,
      JSON.stringify(data),
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};


export const resetPassword = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/reset`,
      JSON.stringify(data),
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};

/*
export const verifyResetToken = async (tokenReset) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios.get(
      `${API_URL}/api/auth/reset/${tokenReset}`,
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};


export const logout = () => {
  localStorage.removeItem("user");
  window.location.replace("/login");
};
*/