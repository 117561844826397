import logo from './logo.svg';
import './App.scss';
import Login from './views/Login/Login';
import Forgot from './views/Forgot/Forgot';
import Register from './views/Register/Register';
import Navbar from './components/navbar/Navbar';
import Home from './views/Home/Home';
import MyProfile from './views/MyProfile/MyProfile';
import MyActivities from './views/MyActivities/MyActivities';
import Activities from './views/Activities/Activities';
import Activity from './views/Activity/Activity';
import Certificates from './views/Certificates/Certificates';
import Invoices from './views/Invoices/Invoices';
import LayoutNavbar from './layout/LayoutNavbar';
import LoggedIn from "./components/validate/LoggedIn";
import NotLoggedIn from "./components/validate/NotLoggedIn";
import Navigation from './components/validate/Navigation';
import PaySuccess from './views/PaySuccess/PaySuccess';
import { Outlet, Route, Routes, BrowserRouter as Router } from "react-router-dom";
import PayPending from './views/PayPending/PayPendig';
import PayFailed from './views/PayFailed/PayFailed';
import Join from './views/Join/Join';
import NotFound from './views/notFound/notFound';

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes that needs a navbar will need to go as children of this Route component */}
        <Route element={<LoggedIn />}>
          <Route
            path="/myActivities"
            element={<LayoutNavbar page={<MyActivities />} />}
          />
          <Route
            path="/profile"
            element={<LayoutNavbar page={<MyProfile />} />}
          />
          <Route
            path="/certificates"
            element={<LayoutNavbar page={<Certificates />} />}
          />
          <Route
            path="/invoices"
            element={<LayoutNavbar page={<Invoices />} />}
          />
          <Route
            path="/paySuccess"
            element={<LayoutNavbar page={<PaySuccess />} />}
          />
          <Route
            path="/payPending"
            element={<LayoutNavbar page={<PayPending />} />}
          />
          <Route
            path="/payFailed"
            element={<LayoutNavbar page={<PayFailed />} />}
          />
          <Route path="/join" element={<LayoutNavbar page={<Join />} />} />
        </Route>
        <Route element={<NotLoggedIn />}>
        <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/register" element={<Register />} />
        </Route>
        <Route element={<Navigation />}>
          
          <Route path="/" element={<LayoutNavbar page={<Home />} />} />
          <Route
            path="/activities"
            element={<LayoutNavbar page={<Activities />} />}
          />
          <Route
            path="/activity/:id"
            element={<LayoutNavbar page={<Activity />} />}
          />
          <Route path="*" element={<LayoutNavbar page={<NotFound />} />} />
        </Route>
      </Routes>
    </Router>
  );
}


export default App;
