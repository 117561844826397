import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
import { getAllCertificatesThunk } from './thunks'

const initialState = {
  certificatesAll: [],
  error: false,
  eDescription: {},
  message: ""
};

export const certificatesSlice = createSlice({
  name: "certificates",
  initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPending(
          getAllCertificatesThunk
        ),
        (state) => {
          state.loading = true;
          state.error = false;
          state.errorDescription = {};
        }
      )

      .addMatcher(
        isFulfilled(getAllCertificatesThunk),
        (state, action) => {
          state.loading = false;
          state.error = false;
          state.eDescription = {};
          state.certificatesAll = action.payload.data.certificatesAll
        }
      )
  
      .addMatcher(
        isRejectedWithValue(
          getAllCertificatesThunk
        ),
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.eDescription = action.payload;
          state.message = action.payload.message
        }
      );
  },
});

export default certificatesSlice.reducer;

