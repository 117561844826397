import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { getInscriptionThunk, subscribeThunk } from './thunks'

const initialState = {
    inscription: {},
    inscriptionLoading: false,
    inscriptionError: null,
    inscriptionMessage: null,
    createInscription: null,
  
};

export const inscriptionsSlice = createSlice({
    name: 'inscriptions',
    initialState,
    reducers: {
        swipeInscriptionData: (state) => {
            state.inscriptionError = null;
            state.inscriptionMessage = null;
        },
        hideToast(state) {
            state.inscriptionError = false
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                isPending(subscribeThunk, getInscriptionThunk),
                (state) => {
                    state.inscriptionLoading = true;
                    state.inscriptionError = false;
                    state.inscriptionMessage = null;
                    state.createInscription = null
                    
                }
            )
            .addMatcher(
                isFulfilled(subscribeThunk),
                (state, action) => {
                    state.inscriptionLoading = false;
                    state.inscriptionError = null;
                    //Este mensaje debe venir del servidor
                    state.inscriptionMessage = action.payload.message;
                    state.inscription = action.payload.inscription;
                    state.createInscription = true
                    
                }
            )
             .addMatcher(
                isFulfilled(getInscriptionThunk),
                (state, action) => {
                    state.inscriptionLoading = false;
                    state.inscriptionError = null;
                    //Este mensaje debe venir del servidor
                    state.inscriptionMessage = action.payload.message;
                    state.inscription = action.payload.inscription;
                    
                }
            )
            .addMatcher(
                isRejectedWithValue(
                    subscribeThunk,
                    getInscriptionThunk
                ),
                (state, action) => {
                    state.inscriptionLoading = false;
                    state.createInscription = false
                    state.inscriptionError = true
                    state.inscriptionMessage = "Ha ocurrido un error, por favor intente nuevamente en unos momentos"
                    state.inscriptionData = {};
                    
                }
            );
    }
});

export const { swipeInscriptionData, hideToast } = inscriptionsSlice.actions;

export default inscriptionsSlice.reducer;
