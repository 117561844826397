import { createAsyncThunk} from '@reduxjs/toolkit';
import { getAccountService, subscribeService, updateUser } from '../../services/userService';


  export const getAccountThunk = createAsyncThunk(
    'users/getUserStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getAccountService();
        if (req.success === false || req.response?.status === 401) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  
  export const subscribeThunk = createAsyncThunk(
    'users/subscribeThunk',
    async (data, {rejectWithValue}) => {
      try {
        const req = await subscribeService(data);
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const updateUserThunk = createAsyncThunk(
    'users/updateUserStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await updateUser(data);
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );
  



  