import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './Modal.scss'

export default function ModalComponent(props) {
    const { onHide, show, value1, setValue1, submit, error1, errorMsg, value2, setValue2, error2 } = props

    return (
        <Modal id={'modalComponent'} show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Aval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{marginBottom: '-2%'}}>Para poder hacerse socio es requisito completar la siguiente informacion</p>
                <Form >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese su aval 1"
                            autoFocus
                            value={value1}
                            onChange={(e) => setValue1(e.target.value)}
                        />
                        {
                            error1
                                ?
                                <p style={{ color: 'red' }}>{errorMsg}</p>
                                :
                                null
                        }
                        <Form.Label></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese su aval 2"
                            autoFocus
                            value={value2}
                            onChange={(e) => setValue2(e.target.value)}
                        />
                        {
                            error2
                                ?
                                <p style={{ color: 'red' }}>{errorMsg}</p>
                                :
                                null
                        }
                    </Form.Group>

                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={submit}>
                    Continuar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
