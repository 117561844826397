import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { siteDataThunk } from './thunks'

const initialState = {
  siteData: {},
  error: false,
  eDescription: {},
  nextActivities: []
};

export const siteDataSlice = createSlice({
  name: "siteData",
  initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPending(
            siteDataThunk
        ),
        (state) => {
          state.loading = true;
          state.error = false;
          state.errorDescription = {};
        }
      )

      .addMatcher(
        isFulfilled(siteDataThunk),
        (state, action) => {
          state.loading = false;
          state.error = false;
          state.eDescription = {};
          state.homeData = action.payload.homeView;
          state.featuredActivity = action.payload.featuredActivity;
          state.nextActivities = action.payload.nextActivities;
          state.userActivityFeatured = action.payload.userActivityFeatured
          state.userSubscribeNextActivities = action.payload.userSubscribeNextActivities
        }
      )

      .addMatcher(
        isRejectedWithValue(
            siteDataThunk
        ),
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.eDescription = action.payload;
        }
      );
  },
});

export default siteDataSlice.reducer;

