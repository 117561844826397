import { Navigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import { Outlet } from 'react-router';
import { getAccountThunk } from '../../redux/user/thunks';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

export default function NotLoggedIn() {
  const dispatch = useDispatch()
  let loggedIn = useSelector(state => state.auth.token);
  let user = useSelector(state => state.user.data);

  useEffect(() => {
    dispatch(getAccountThunk())
  }, [loggedIn])

    return (
      <>
      {(loggedIn && user._id) ? <Navigate to="/" /> : null}
      <Outlet />
      </>
    )
}


