import React, { useState, useEffect, useReducer } from 'react';
import "./MyProfile.scss"
import arrowTitle from '../../assets/arrow_title.svg'
import Input from '../../components/input/Input'
import { useSelector, useDispatch } from 'react-redux';
import { updateUserThunk } from '../../redux/user/thunks';
import Backdrop from "../../components/backdrop/Backdrop"
import Toast from "../../components/toast/Toast"
import { hideToast } from '../../redux/user/userSlice';
import moment from 'moment';
import { getAccountThunk } from '../../redux/user/thunks';

export default function MyProfile() {
    const [isResident, setIsResident] = useState(false)
    const member = true
    const [data, setData] = useState()
    const dispatch = useDispatch()
    let userData = useSelector(state => state.user.data);
    let loading = useSelector(state => state.user.loading);
    let error = useSelector(state => state.user.error);
    let message = useSelector(state => state.user.message);
    let visibleToast = useSelector(state => state.user.showToast);


    useEffect(() => {
        if (userData) {
            setData(Object.assign({}, userData))
            setIsResident(userData.resident ? true : false)
        }
        
    }, [userData])

    const handleToast = () => {
        dispatch(hideToast())
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };
    const editUserData = (e) => {
        e.preventDefault()
        data.resident = isResident
        dispatch(updateUserThunk(data))
    }

    const onChangeResident = (e) => {
        setIsResident(e.target.checked)
    }

    const toLocalDate = (date) => {
        return moment(date).local().format('YYYY-MM-DD')
    }

    return (
        <>

            <main id='register'>
                <section className='section'>
                    <div className="columns is-centered">
                        <div className="column is-4">
                            <h1 className="titleProp">
                                <span className='title-border-white'>MI PERFIL</span> <br />
                                <img src={arrowTitle} alt="" width="50px" />
                            </h1>
                        </div>
                        <form className="column is-8">
                            {
                                userData?.resident ?

                                    <div className="field column is-12 header-form">
                                        <div className="control" style={{ paddingLeft: '3%' }}>
                                            <label className="radio mb-3 has-text-white">
                                                <input type="checkbox"
                                                    checked={isResident}
                                                    onChange={onChangeResident} />
                                                Soy residente
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="columns is-multiline is-centered">
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Nombre"
                                            handleChange={handleChange}
                                            value={data?.name}
                                            name={"name"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Apellido"
                                            handleChange={handleChange}
                                            value={data?.lastName}
                                            name={"lastName"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Email"
                                            handleChange={handleChange}
                                            value={data?.email}
                                            name={"email"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="DNI"
                                            handleChange={handleChange}
                                            value={data?.dni}
                                            name={"dni"}
                                        />
                                    </div>
                                </div>

                                <div className="field column is-6">
                                    <div className="control">
                                        <label style={{ color: 'white', fontSize: 18 }}>Genero</label>
                                        <select
                                            style={{
                                                width: '100%',
                                                minHeight: '50px',
                                                justifyContent: 'center',
                                                backgroundColor: 'white',
                                                border: 'none',
                                                fontSize: '1.3rem',
                                                outline: 'none',
                                                color: '#5d677f',
                                                paddingLeft: '10px',
                                            }}
                                            onChange={handleChange}
                                            value={data?.genre}
                                            name={"genre"}
                                        >
                                            <option hidden></option>
                                            <option value="m">Masculino</option>
                                            <option value="f">Femenino</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Celular"
                                            handleChange={handleChange}
                                            value={data?.phone}
                                            name={"phone"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="date"
                                            placeholder="Fecha de Nacimiento"
                                            handleChange={handleChange}
                                            value={toLocalDate(data?.dateOfBirth)}
                                            name={"dateOfBirth"}
                                        />
                                    </div>
                                </div>

                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Dirección"
                                            handleChange={handleChange}
                                            value={data?.address}
                                            name={"address"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="País"
                                            handleChange={handleChange}
                                            value={data?.country}
                                            name={"country"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Provincia"
                                            handleChange={handleChange}
                                            value={data?.province}
                                            name={"province"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Localidad"
                                            handleChange={handleChange}
                                            value={data?.zone}
                                            name={"zone"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Código postal"
                                            handleChange={handleChange}
                                            value={data?.postalCode}
                                            name={"postalCode"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Titulo"
                                            handleChange={handleChange}
                                            value={data?.title}
                                            name={"title"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-6">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Profesión"
                                            handleChange={handleChange}
                                            value={data?.profession}
                                            name={"profession"}
                                        />
                                    </div>
                                </div>
                                <div className="field column is-12">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Especialidad"
                                            handleChange={handleChange}
                                            value={data?.specialty}
                                            name={"specialty"}
                                        />
                                    </div>
                                </div>
                                {
                                    userData?.membershipStatus === 'authorized'
                                        ?
                                        <div className="field column is-12">
                                            <div className="control">
                                                <Input
                                                    className={"input is-medium mb-5"}
                                                    type="text"
                                                    placeholder="Aval 1"
                                                    handleChange={handleChange}
                                                    value={data?.aval1}
                                                    name={"aval1"}
                                                />
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    userData?.membershipStatus === 'authorized'
                                        ?
                                        <div className="field column is-12">
                                            <div className="control">
                                                <Input
                                                    className={"input is-medium mb-5"}
                                                    type="text"
                                                    placeholder="Aval 2"
                                                    handleChange={handleChange}
                                                    value={data?.aval2}
                                                    name={"aval2"}
                                                />
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                <div className="field column is-12 pb-5">
                                    <div className="control">
                                        <Input
                                            className={"input is-medium mb-5"}
                                            type="text"
                                            placeholder="Lugar de trabajo"
                                            handleChange={handleChange}
                                            value={data?.jobAddress}
                                            name={"jobAddress"}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div style={{ paddingBottom: '5%' }} className="field column is-12 fot-register">

                                <button
                                    onClick={editUserData}
                                    className="button is-fullwidth">
                                    Cambiar datos
                                </button>

                            </div>

                        </form>
                    </div>
                </section>
            </main>
            <Toast visible={visibleToast} handleToast={handleToast} message={message} title={"Editar datos"} />
            <Backdrop show={loading} />
        </>
    )
}