import React from "react";
import "./ArticleModal.scss";
import DietaryRequirements from "./DietaryRequirements";

export default function Section2(props) {
  let { activity, closeModal, show_other, handleChange, sub_type_state } =
    props;

  return (
    <React.Fragment>
      <section className="modal-card-body">
        <div className="columns is-multiline">
          
          {activity.paid && (
            <>
            <div className="field column is-6">
              <div className="control">
                <label style={{ color: "#0157a0", fontSize: 18 }}>
                  Código de beca (Si aplica)
                </label>
                <input type="text" name={"code"} onChange={handleChange} />
              </div>
            </div>
            <div className="field column is-6 mobile-hidden"></div>
            </>
          )}

          
          <div className="field column is-6">
            <div className="control">
              <label style={{ color: "#0157a0", fontSize: 18 }}>
                Tipo de inscripción
              </label>
              <select name={"participant_type"} onChange={handleChange}>
                <option hidden></option>
                <option value="participante">Participante</option>
                <option value="disertante">Participante y disertante</option>
              </select>
            </div>
          </div>
          <DietaryRequirements
            handleChange={handleChange}
            sub_type_state={sub_type_state}
            show_other={show_other}
          />
        </div>

        {/* <button
          onClick={closeModal}
          className="modal-close is-large"
          aria-label="close"
        ></button> */}
      </section>
    </React.Fragment>
  );
}
