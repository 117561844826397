import axios from "axios";
import { authHeader } from './utils'
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES
import {API_URL} from '../config'

export const getUser = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${API_URL}/user/getOne/${id}`,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getAccountService = async () => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${API_URL}/user/account`,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};


export const subscribeService = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${API_URL}/user/subscribe/${data.aval1}/${data.aval2}`,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateUser = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.put(
      `${API_URL}/user/updateAccount`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};