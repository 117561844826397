import React from "react";
import "./ArticleModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

export default function Footer_button({btn_click, text, type, price}) {
  return (
    <button
      onClick={() => btn_click(type, price)}
      className="button mr-5 blue"
    >
      <FontAwesomeIcon style={{ marginRight: "5%" }} icon={faCalendar} />
     {text}
      {price ? ` ($${price})` : ""}
    </button>
  );
}
