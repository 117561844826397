import { createAsyncThunk} from '@reduxjs/toolkit';
import { login, register, forgot, resetPassword/*, verifyResetToken*/ } from '../../services/authService'

export const loginThunk = createAsyncThunk(
    'user/loginStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await login(data);
        if(req.status !== 200){
          return rejectWithValue(req.data)
        }
        if (!req.data.success) { 
           return rejectWithValue(req.data)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );
  
  export const registerThunk = createAsyncThunk(
    'user/registerStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await register(data);
     
        if(req.status !== 200){
          return rejectWithValue(req.data)
        }
        if (!req.data.success) { 
           return rejectWithValue(req.data)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const forgotThunk = createAsyncThunk(
    'user/forgotStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await forgot(data);
        if(req.status !== 200){
          return rejectWithValue(req.data)
        }
        if (!req.data.success) { 
           return rejectWithValue(req.data)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e.response.data)
      }
    }
  );

  export const resetThunk = createAsyncThunk(
    'user/resetPasswordStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await resetPassword(data);
        if(req.status !== 200){
          return rejectWithValue(req.data)
        }
        if (!req.data.success) { 
           return rejectWithValue(req.data)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e.response.data)
      }
    }
  );

/*
  export const verifyResetTokenThunk = createAsyncThunk(
    'user/verifyResetTokenStatus',
    async (data, {rejectWithValue}) => {
      try {
        const response = await verifyResetToken(data);
        return response.data;
      } catch (e) {
        return rejectWithValue(e.response.data)
      }
    }
  );
*/
  


  