import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { allActivitiesThunk, myActivitiesThunk, activitySubscribeThunk, checkPaymentThunk, hideToastThunk } from './thunks'

const initialState = {
  allActivities: [],
  myActivities: [],
  error: false,
  eDescription: {},
  subscribeActivityRefresh: false,
  checkPayment: null,
  message: "",
  redirect:false
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    hideToast(state) {
      state.message = ''
      state.showToast = false
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(allActivitiesThunk.fulfilled, (state, action) => { 
      state.allActivities = action.payload.allActivities;
      state.redirect = false;
    })
    .addCase(myActivitiesThunk.fulfilled, (state, action) => { 
      state.myActivities = action.payload.myActivities;
      state.redirect = false;
    })
    .addCase(activitySubscribeThunk.fulfilled, (state, action) => { 
      state.subscribeActivitySuccess = action.payload.success;
      state.subscribeActivityRefresh = !state.subscribeActivityRefresh;
      state.subscribeActivityLink = action.payload.link;
      state.message = action.payload.message
      state.showToast = true
      state.redirect = true;
    })
    .addCase(checkPaymentThunk.fulfilled, (state, action) => { 
      state.checkPayment = action.payload.verification;
      state.redirect = false;
    })
    .addCase(activitySubscribeThunk.rejected, (state, action) => { 
      state.message = action.payload.message
      state.showToast = true
      state.redirect = false;
    })


      .addMatcher(
        isPending(
            allActivitiesThunk, myActivitiesThunk, activitySubscribeThunk, checkPaymentThunk
        ),
        (state) => {
          state.loading = true;
          state.error = false;
          state.errorDescription = {};
          state.redirect = false;
        //  state.message = ""
        }
      )

      .addMatcher(
        isFulfilled(allActivitiesThunk, myActivitiesThunk, activitySubscribeThunk, checkPaymentThunk),
        (state, action) => {
          state.loading = false;
          state.error = false;
          state.eDescription = {};
          
         
          /*state.allActivities = action.payload.allActivities;
          state.myActivities = action.payload.myActivities;
          state.subscribeActivitySuccess = action.payload.success;
          state.subscribeActivityLink = action.payload.link;*/
        }
      )
      

      .addMatcher(
        isRejectedWithValue(
            allActivitiesThunk, myActivitiesThunk, activitySubscribeThunk, checkPaymentThunk
        ),
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.eDescription = action.payload;
          state.message = action.payload.message;
        }
      );
  },
});

export const { hideToast } = activitiesSlice.actions;
export default activitiesSlice.reducer;

