import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
import { getAllBillingsThunk } from './thunks'

const initialState = {
  invoicesSubscription: [],
  invoicesActivities: [],
  error: false,
  eDescription: {},
  message: ""
};

export const billingsSlice = createSlice({
  name: "billings",
  initialState,
  reducers: {
    changeLoading(state) {
      state.loading = state
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPending(
            getAllBillingsThunk
        ),
        (state) => {
          state.loading = true;
          state.error = false;
          state.errorDescription = {};
        }
      )

      .addMatcher(
        isFulfilled(getAllBillingsThunk),
        (state, action) => {
          state.loading = false;
          state.error = false;
          state.eDescription = {};
          state.invoicesSubscription = action.payload.data.invoicesSubscription
          state.invoicesActivities = action.payload.data.invoicesActivities
        }
      )
  
      .addMatcher(
        isRejectedWithValue(
            getAllBillingsThunk
        ),
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.eDescription = action.payload;
          state.message = action.payload.message
        }
      );
  },
});

export const { changeLoading } = billingsSlice.actions;
export default billingsSlice.reducer;

