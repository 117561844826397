import React, { useState } from 'react';
import "./ArticleModal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faLocation, faCircleUser, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import FooterButton from './Footer_button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Footer({ isMember, sub_type_state, set_sub_type_state, setSection, section, isVisibleModal, activity, closeModal, isSuscribed, suscribeToActivity }) {

  const userData = useSelector(state => state.user.data);
  const user = useSelector(state => state.user); // Asumiendo que usas Redux para la autenticación
  const [state_price, set_state_price] = useState(null);


  const btn_click = (type, price) => {
    set_sub_type_state(type);
    setSection(2);
    set_state_price(price);
  }

  if (section === 2) {
    return (
      <footer className="modal-card-foot special_requests">
        <button
          onClick={() => setSection(1)}
          className="button mr-5 blue"
        >
          Cancelar
        </button>
        <button
          onClick={() => suscribeToActivity({
            id: activity._id,
            subscription_type: sub_type_state,
          })}
          className="button mr-5 blue"
        >
          Finalizar inscripción (${state_price})
        </button>
      </footer>
    );
  }

  if (!user.data?._id) {
    return (
      <footer className="modal-card-foot special_requests">
        <Link to={"/login"}>
          <button className="button mr-5 blue">
            Ingresar
          </button>
        </Link>
      </footer>
    );
  }

  if (userData.membershipStatus !== 'authorized' && activity.userType === 'authorized') {
    return (
      <footer className="modal-card-foot special_requests">
        <Link to={"/join"}>
          <button className="button mr-5 blue">
            Asociate para inscribirte
          </button>
        </Link>
      </footer>
    );
  }

  if (isSuscribed) {
    return (
      <footer className="modal-card-foot special_requests">
        <button disabled className="button ml-5 bg-gray">
          Ya estoy inscripto
        </button>
      </footer>
    );
  }

  return (
    <footer className="modal-card-foot special_requests">
      {activity.type === "mixta" ? (
        <>
          <FooterButton
            btn_click={btn_click}
            activity={activity}
            text={"Asistiré presencialmente"}
            type={"presencial"}
            price={activity.price}
          />
          <FooterButton
            btn_click={btn_click}
            activity={activity}
            text={"Asistiré virtualmente"}
            type={"virtual"}
            price={activity.virtualPrice}
          />
        </>
      ) : (
        <FooterButton
          btn_click={btn_click}
          activity={activity}
          text={"Inscribirme"}
          type={activity.type}
          price={activity.price ? activity.price : activity.virtualPrice}
        />
      )}
    </footer>
  );
}
