import React, { useState, useEffect, useContext } from 'react';
import Img from '../../assets/defaultActivity3.jpeg'
import ArticleModal from "../molecules/articleModal/ArticleModal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRightLong, faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import Moment from 'moment';
import { PUBLIC_IMG_URL } from '../../config/index';

export default function NextActivity(props) {
    const { activity, setActivitySelected, userSubscribeNextActivities, index } = props
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const [section, setSection] = useState(1)
    const [show_other, set_show_other] = useState(false);
    const [data, setData] = useState({});

    const handleChange = (e) => {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
        e.target.name === "dietary_restrictions" &&
          (e.target.value === "Otro"
            ? set_show_other(true)
            : set_show_other(false));
      };

    const closeModal = () => {
        setIsVisibleModal(false)
    }

    const showMore = () => {
        setIsVisibleModal(true)
    }

    const suscribeToActivity = () => {
        closeModal()
        setActivitySelected({...activity, ...data})
    }
    
    return (
      <>
        {activity ? (
          <article className="column is-12">
            <div
              style={{ backgroundColor: "white" }}
              className="is-flex is-flex-direction-row"
            >
              <img
                className="imgNextActivities"
                src={`${PUBLIC_IMG_URL}${activity.imgPath}`}
                alt=""
              />

              <div className="containerDescritpionNextActivities is-flex is-flex-direction-column is-justify-content-space-between pl-4">
                <h4 className="subtitle is-5 blue">{activity.title}</h4>

                {userSubscribeNextActivities.includes(activity._id) ? (
                  <span>Ya estas inscripto</span>
                ) : null}
                <div className="control date">
                  <span
                    style={{ fontSize: 16 }}
                    className="buttonFeatured gray"
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "2%" }}
                      icon={faCalendar}
                    />
                    {Moment(activity.startDate).format("DD-MM-YYYY")}
                  </span>
                  <span
                    style={{ marginLeft: "5%", fontSize: 16 }}
                    className="buttonFeatured gray"
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "2%" }}
                      icon={faClock}
                    />
                    {Moment(activity.startDate).format("h:mm:ss a")}
                  </span>
                </div>
                <span className="skyBlue icon-text">
                  <span
                    onClick={showMore}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Leer más
                  </span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </span>
                <ArticleModal
                  isSuscribed={userSubscribeNextActivities.includes(
                    activity._id
                  )}
                  paid={false}
                  suscribeToActivity={suscribeToActivity}
                  activity={activity}
                  isVisibleModal={isVisibleModal}
                  closeModal={closeModal}
                  setSection={setSection}
                  section={section}
                  handleChange={handleChange}
                  how_other={show_other}
                  set_show_other={set_show_other}
                />
              </div>
            </div>
            {index < 2 ? (
              <hr className="bg-gray" style={{ marginTop: "5%" }}></hr>
            ) : null}
          </article>
        ) : null}
      </>
    );

}