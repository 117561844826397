import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "./Forgot.scss"
import arrowTitle from '../../assets/arrow_yelow_1.svg'
import Input from '../../components/input/Input'
import { useDispatch, useSelector } from 'react-redux';
import { forgotThunk, resetThunk } from '../../redux/auth/thunks';
import Backdrop from "../../components/backdrop/Backdrop"
import Toast from "../../components/toast/Toast"
import { hideToast } from '../../redux/auth/authSlice';

export default function Forgot() {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [dni, setDNI] = useState('');
    const [newPass, setNewPass] = useState('');
    const dispatch = useDispatch()
    let loading = useSelector(state => state.auth.loading);
    let error = useSelector(state => state.auth.error);
    let message = useSelector(state => state.auth.message);
    let reset = useSelector(state => state.auth.reset);

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    };

    const handleChangeCode = (e) => {
        setCode(e.target.value)
    };

    const handleChangeDNI = (e) => {
        setDNI(e.target.value)
    };

    const handleChangeNewPass = (e) => {
        setNewPass(e.target.value)
    };

    const sendEmail = (event) => {
        event.preventDefault()
        dispatch(forgotThunk({email, dni}))
    };

    const onReset = (event) => {
        event.preventDefault()
        dispatch(resetThunk({email, dni, code, password: newPass}))
    };

    const handleToast = () => {
        dispatch(hideToast())
    }

    return (
        <React.Fragment>
            <main id='login'>
                        {
                            reset ?
                            <section className='section mb-4'>
                            <div className="columns is-centered">
                                <div className="column is-6">
                                    <h1 className="titleProp">
                                        <span className='title-border-white'>CAMBIAR</span> <br />
                                        <span className='title-bold-white'>CONTRASEÑA<br /> <img src={arrowTitle} alt="" width="50px" /> </span>
                                    </h1>
                                </div>
                                <form className="column is-6">
                                <div><p style={{ color: 'yellow' }}>{message}</p></div>
                                    <div className="field">
                                        <div className="control">
                                        
                                            <Input
                                                className={"input is-medium mb-5"}
                                                type="text"
                                                placeholder="Codigo"
                                                value={code}
                                                handleChange={handleChangeCode}
                                                name={'code'}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control">
                                            <Input
                                                className={"input is-medium mb-5"}
                                                type="password"
                                                placeholder="Nueva Contraseña"
                                                value={newPass}
                                                handleChange={handleChangeNewPass}
                                                name={'password'}
                                            />
                                        </div>
                                    </div>
                                    <div className="field is-grouped is-grouped-centered">
                                        <div className="control">
                                            <button
                                                onClick={onReset}
                                                className="button bg-white blue">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                    <span className="mt-5 help is-light">
                                        ¿Primera vez aquí?
                                        <Link style={{ color: "white" }} to="/register" className="yelow ml-2">
                                            Registrate ahora.
                                        </Link>
                                    </span>
                                </form>
                            </div>
                        </section>
                            :
                            <section className='section mb-4'>
                            <div className="columns is-centered">
                                <div className="column is-6">
                                    <h1 className="titleProp">
                                        <span className='title-border-white'>CAMBIAR</span> <br />
                                        <span className='title-bold-white'>CONTRASEÑA<br /> <img src={arrowTitle} alt="" width="50px" /> </span>
                                    </h1>
                                </div>
                                <form className="column is-6">
                                    <div className="field">
                                        <div className="control">
                                            <Input
                                                className={"input is-medium mb-5"}
                                                type="text"
                                                placeholder="Email (dejar vacio en caso de no recordarlo)"
                                                value={email}
                                                handleChange={handleChangeEmail}
                                                name={'code'}
                                            />
                                        </div>
                                        <div className="control">
                                            <Input
                                                className={"input is-medium mb-5"}
                                                type="number"
                                                placeholder="DNI"
                                                value={dni}
                                                handleChange={handleChangeDNI}
                                                name={'dni'}
                                            />
                                        </div>
                                    </div>
                                    <div className="field is-grouped is-grouped-centered">
                                        <div className="control">
                                            <button
                                                onClick={sendEmail}
                                                className="button bg-white blue">
                                                Enviar
                                            </button>
                                        </div>
                                    </div>
                                    <span className="mt-5 help is-light">
                                        ¿Primera vez aquí?
                                        <Link style={{ color: "white" }} to="/register" className="yelow ml-2">
                                            Registrate ahora.
                                        </Link>
                                    </span>
                                </form>
                            </div>
                        </section>
                        }
            </main>
            <Toast visible={error ? true : false} handleToast={handleToast} message={message} title={"Login"} />
            <Backdrop show={loading} />
        </React.Fragment>
    )
}