import React from 'react';
import { useLocation } from "react-router-dom"
import "./PayFailed.scss"

export default function PayFailed(props) {
    let query = useQuery();

    return (
        <div
        className="titleProp"
        style={{
          fontSize: "3.4rem",
          lineHeight: "3.4rem",
          paddingTop: "10rem",
        }}
      >
        <div className={"title-bold-blue text-center"}>
          <p>SU PAGO HA SIDO RECHAZADO</p>
          <p>POR FAVOR INTENTE REANUDARLO</p>
        </div>
      </div>
    )
}


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}