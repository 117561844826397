import React, { useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { Outlet } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountThunk } from '../../redux/user/thunks';
import {setToken} from '../../redux/auth/authSlice';
import { navigation} from '../../redux/auth/authSlice';

export default function LoggedIn() {
  let loggedIn = useSelector(state => state.auth.token);
  let token = localStorage.getItem('token');
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAccountThunk())
  }, [dispatch, loggedIn])

    return (
      <>
      {!loggedIn && setToken(token)}
      {!loggedIn & !token ? <Navigate to="/login" /> : null}
      <Outlet />
      </>
    )
}


