import axios from "axios";
import { authHeader } from './utils'
import {API_URL} from '../config'


export const allActivitiesService = async () => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${API_URL}/activities/all`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const myActivitiesService = async () => {
    try {
      let options = {
        headers: {
          "content-type": "application/json",
          ...authHeader()
        },
      };
      const response = await axios.get(
        `${API_URL}/activities/myActivities`,
        options
      );
      return response.data;
    } catch (e) {
      return e;
    }
  };

  
export const activitiySubscribeService = async (activity) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.post(
      `${API_URL}/activities/subscribe/${activity.id}`,
      activity,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};


export const checkPaymentService = async (paymentId) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${API_URL}/billing/checkPayment/${paymentId}`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};



